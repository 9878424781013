<!-- <mat-toolbar
	class="header"
	[ngClass]="{
		'not-top': state === 'not-top' || partnerCode !== 'carsomeCapital'
	}"
> -->
<mat-toolbar class="header not-top">
	<div class="menu-container">
		<a href="/">
			<img
				src="./assets/image/{{ partnerCode }}/{{ partnerCode }}-logo-white.png"
				alt=""
				class="white-page-logo"
				*ngIf="partnerCode === 'carsomeCapital'"
			/>
		</a>
		<a href="/">
			<img
				src="./assets/image/{{ partnerCode }}/{{ partnerCode }}-logo.png"
				alt=""
				class="page-logo"
			/>
		</a>
		<span fxFlex></span>
		<span class="mobile-actions">
			<button
				mat-stroked-button
				[matMenuTriggerFor]="language"
				class="header-language mh-xs"
			>
				<img src="assets/image/language.png" alt="" />
				{{ "language." + translate.currentLang | translate }}
			</button>
			<mat-menu #language="matMenu">
				<button mat-menu-item (click)="switch('en')">
					<span>{{ "language.en" | translate }}</span>
				</button>
				<button mat-menu-item (click)="switch('bm')">
					<span>{{ "language.bm" | translate }}</span>
				</button>
			</mat-menu>

			<!-- <img
				class="mobile-whatsapp"
				src="assets/image/whatsapp-mobile.png"
				alt=""
				(click)="whatsppRedirect()"
			/> -->

			<button mat-icon-button (click)="openMenu()" class="header-menu">
				<mat-icon>menu</mat-icon>
			</button>
		</span>
		<span class="desktop-actions">
			<!-- <button mat-button>{{'header.insurance' | translate}}</button> -->

			<!-- <a mat-button target="_blank" href="https://www.policystreet.com/articles">{{'header.blog' | translate}}</a>
      <a mat-button target="_blank" href="https://www.policystreet.com/contact-us">{{'header.contactUs' | translate}}</a> -->
			<!-- <button mat-button>{{'header.contactUs' | translate}}</button> -->
		</span>
	</div>
</mat-toolbar>
