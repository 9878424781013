<div class="mdTitle-f">
	<img src="assets/image/shield-icon.png" class="title-img" alt="" />
	{{ "common.carProtectionPlan" | translate }}
</div>
<div class="content-wrapper">
	<div>
		<div class="label-desc">{{ "common.typeOfInsurance" | translate }} :</div>
		<div class="label-value">
			<ng-container [ngSwitch]="data.insuranceType">
				<ng-container *ngSwitchCase="'COM'">{{
					"common.COM" | translate
				}}</ng-container>
				<ng-container *ngSwitchCase="'TPFT'">{{
					"common.TPFT" | translate
				}}</ng-container>
				<ng-container *ngSwitchDefault>{{
					"common.TP" | translate
				}}</ng-container>
			</ng-container>
		</div>
	</div>
	<div>
		<div class="label-desc">{{ "common.effectivePeriod" | translate }} :</div>
		<div class="label-value">
			{{ data.effectiveDate | date : "dd MMM yyyy" }}
		</div>
	</div>
</div>
