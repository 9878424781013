<div>
	<div class="success-graphic">
		<img src="assets/image/submit-success-dialog.png" alt="" />
		<div>
			{{ "success.thankYou" | translate }}
		</div>
	</div>
	<div class="success-content">
	<span *ngIf="data?.businessType === 'TransferOwnership' || !data?.businessType ">
		<span *ngIf="onOperation">
			{{
				"success.quotationsProcessed"
					| translate : { responseTime: responseTime }
			}}
			{{ "success.emailUs1" | translate }}
            <a href="mailto:customerservice@policystreet.com">customerservice@policystreet.com</a>
			{{ "success.emailUs2" | translate }}
			
		</span>
		<span *ngIf="!onOperation">
			{{ "success.getBack" | translate }} {{ "success.dropEmail" | translate }}
			<a href="mailto:info@policystreet.com">info@policystreet.com</a>.
		</span>
	</span>

		<span *ngIf="data?.businessType === 'Renewal' ">
				{{ "success.selected1" | translate }} 
				<span [style.color]="'#49b0ff'"><b>{{ "common.AV" | translate }}</b></span>
				{{ "success.selected2" | translate }} 
				<span [style.color]="'#49b0ff'"><b>{{ data.email }}</b></span>

				<span *ngIf="onOperation">
					{{ "success.selectedOpenHours" | translate }}
				</span>

				<span *ngIf="!onOperation">
				{{ "success.selectedCloseHours" | translate }}
				</span>
		</span>
		<button mat-flat-button mat-primary mat-dialog-close>
			{{ "success.close" | translate }}
		</button>
	</div>
</div>
