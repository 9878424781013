import { RangeSliderModule } from "../share/component/range-slider/range-slider.module";
import { InsurerDetailsModule } from "./../share/component/insurer-details/insurer-details.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material-module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ComparisonComponent } from "./comparison/comparison.component";
import { CustomPipeModule } from "../share/pipe/custom-pipe.module";
import { SubmitSuccessDialogComponent } from "./submit-success-dialog/submit-success-dialog.component";
import { NgxMaskModule } from "ngx-mask";
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { SwiperModule } from "swiper/angular";
import { BeforeExpiredDialogComponent } from "./before-expired-dialog/before-expired-dialog.component";
import { MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";
import { FaqDialogComponent } from "./faq-dialog/faq-dialog.component";
import { GuidesDialogComponent } from "./guides-dialog/guides-dialog.component";
import { BenefitDialogComponent } from "./benefit-dialog/benefit-dialog.component";
import { BenefitModule } from "../share/component/benefit/benefit.module";
import { CarDetailsDialogComponent } from "./car-details-dialog/car-details-dialog.component";
import { CarPlanDialogComponent } from "./car-plan-dialog/car-plan-dialog.component";
import { RenewalReminderDialogComponent } from "./renewal-reminder-dialog/renewal-reminder-dialog.component";
import { UploadDocumentsComponent } from "./upload-documents/upload-documents.component";
import { ThankyouDialogComponent } from "./thankyou-dialog/thankyou-dialog.component";
import { TopupRequestComponent } from "./topup-request/topup-request.component";
import { ThankyouPageComponent } from "./thankyou-page/thankyou-page.component";
import { StaticStylesComponent } from "./static-styles/static-styles.component";
import { SampleDialogComponent } from "./upload-documents/sample-dialog/sample-dialog.component";
import { EditQuotationsComponent } from "./edit-quotations/edit-quotations.component";
import { DealerCaseDialogComponent } from "./dealer-case-dialog/dealer-case-dialog.component";
import { AgentCodeDialogComponent } from "./agent-code-dialog/agent-code-dialog.component";
import { ThankyouPurchaseDialogComponent } from "./thankyou-purchase-dialog/thankyou-purchase-dialog.component";
import { CarVariantDialogComponent } from "./car-variant-dialog/car-variant-dialog.component";
import { LoadingQuotationComponent } from "./loading-quotation/loading-quotation.component";
import { SubmitFailDialogComponent } from './submit-fail-dialog/submit-fail-dialog.component';

@NgModule({
	entryComponents: [
		SubmitSuccessDialogComponent,
		BeforeExpiredDialogComponent,
		RenewalReminderDialogComponent,
	],
	declarations: [
		DashboardComponent,
		ComparisonComponent,
		SubmitSuccessDialogComponent,
		BeforeExpiredDialogComponent,
		FaqDialogComponent,
		GuidesDialogComponent,
		BenefitDialogComponent,
		CarDetailsDialogComponent,
		CarPlanDialogComponent,
		RenewalReminderDialogComponent,
		LoadingQuotationComponent,
		UploadDocumentsComponent,
		ThankyouDialogComponent,
		TopupRequestComponent,
		TopupRequestComponent,
		ThankyouPageComponent,
		StaticStylesComponent,
		SampleDialogComponent,
		EditQuotationsComponent,
		DealerCaseDialogComponent,
		AgentCodeDialogComponent,
		ThankyouPurchaseDialogComponent,
		CarVariantDialogComponent,
		SubmitFailDialogComponent,
	],
	imports: [
		CommonModule,
		MaterialModule,
		DashboardRoutingModule,
		FlexLayoutModule,
		FormsModule,
		SwiperModule,
		ReactiveFormsModule,
		TranslateModule,
		CustomPipeModule,
		NgxMaskModule,
		NgxMatIntlTelInputModule,
		NgxMatSelectSearchModule,
		MatSelectInfiniteScrollModule,
		BenefitModule,
    RangeSliderModule,
		InsurerDetailsModule,
	],
})
export class DashboardModule {}
