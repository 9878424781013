export const PAYMENT_OPTION = {
	razer: "Razer",
	shopback: "Shopback",
};

export const PAYMENT_OPTION_RAZER = "Razer";
export const PAYMENT_OPTION_SHOPBACK = "Shopback";

export const ID_TYPE = {
	police: "Police/Army",
	malaysian: "Malaysian",
	company: "Company",
	foreigner: "Foreigner",
};

export const MARITAL_STATUS = {
	single: "Single",
	married: "Married",
};

export const INSURANCE_TYPE = {
	comprehensive: "COM",
	thirdParty: "TPFT",
};

export const INSURED_TYPE = {
	marketValue: "MV",
	agreedValue: "AV",
};

export const GENDER_TYPE = {
	male: "M",
	female: "F",
};

export const LANGUAGE_TYPE = {
	english: "en",
	malaysian: "ms",
};

// Special for transfer ownership site
export const BUSINESS_TYPE = {
	renewal: "Renewal",
	transferOwnership: "TransferOwnership"
}

export const SUM_INSURED_TYPE = {
	recommended: "recommended",
	other: "otherAmount"
}
// End special consts