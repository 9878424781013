import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-submit-fail-dialog',
  templateUrl: './submit-fail-dialog.component.html',
  styleUrls: ['./submit-fail-dialog.component.scss']
})
export class SubmitFailDialogComponent {
  isWithEHailing = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      isWithEHailing?: boolean;
    },
  ) { 
    if (data.isWithEHailing) this.isWithEHailing = data.isWithEHailing;
  }

  onBackToHome(): void {
		window.location.href = "/";
	}
}
