import { Injectable } from "@angular/core";
import { AlertService } from "./alert.service";
import { TitleCasePipe } from "@angular/common";

@Injectable({
	providedIn: "root",
})
export class FileService {
	constructor(private alertService: AlertService) {}

	async isUploadedFilesValid(params: UploadValidityParams): Promise<boolean> {
		const isLimitValid = await this.isLimitValid(params);
		const isSizeValid = await this.isSizeValid(params);
		const isExtValid = await this.isExtValid(params);

		return isLimitValid && isSizeValid && isExtValid;
	}

	private async isLimitValid(params: UploadValidityParams): Promise<boolean> {
		if (!params.maxLength || params.maxLength === 0) {
			return true;
		}

		const allowedLength = params.maxLength - params.currLength;

		if (allowedLength !== 0) {
			return true;
		}

		this.alertService.openSnackBar(
			"{1} is allowed to upload up to {2} file(s)"
				.replace("{1}", new TitleCasePipe().transform(params.name))
				.replace("{2}", params.maxLength.toString()),
		);

		return false;
	}

	private async isExtValid(params: UploadValidityParams): Promise<boolean> {
		if (!params.accept) return true;

		return new Promise<boolean>((resolve) => {
			const ext = params.file.name.split(".").pop();

			if (params.accept.toLowerCase().includes(ext.toLowerCase())) {
				resolve(true);
			} else {
				this.alertService.openSnackBar(
					"Incorrect file extension. File {1} is not allowed.".replace(
						"{1}",
						params.file.name,
					),
				);
				resolve(false);
			}
		});
	}

	private async isSizeValid(params: UploadValidityParams): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			if (params.file.size > params.size * 1024 * 1024) {
				this.alertService.openSnackBar(
					"Failed to upload {1} - Total file exceeded {2}MB"
						.replace("{1}", params.file.name)
						.replace("{2}", params.size.toString()),
				);
				resolve(false);
			} else {
				resolve(true);
			}
		});
	}
}

interface UploadValidityParams {
	file: File;
	files: FileList;
	name: string;
	currLength: number;
	maxLength: number;
	accept: string;
	size: number;
}
