import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MotorRequestInterface } from "src/app/share/interface/motor.interface";

@Component({
	selector: "app-car-details-dialog",
	templateUrl: "./car-details-dialog.component.html",
	styleUrls: ["./car-details-dialog.component.scss"],
})
export class CarDetailsDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: MotorRequestInterface) {}
}
