import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-renewal-reminder-dialog",
	templateUrl: "./renewal-reminder-dialog.component.html",
	styleUrls: ["./renewal-reminder-dialog.component.scss"],
})
export class RenewalReminderDialogComponent {
	constructor() {}
}
