import { Component, Inject, TemplateRef } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from "@angular/material/dialog";
import { NgIfContext } from "@angular/common";
import { AlertService } from "src/app/share/service/alert.service";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";

@Component({
	selector: "app-car-variant-dialog",
	templateUrl: "./car-variant-dialog.component.html",
	styleUrls: ["./car-variant-dialog.component.scss"],
})
export class CarVariantDialogComponent {
	public linkId: string;
	public carData: {model: string, make: string, year: number, vehicle: string};
	public carVars: {id: string, vehicle: string}[];
	public selectedCar: {id: string, vehicle: string} = null;
	public confirmChanges: boolean;
	public editCarModel: boolean = true;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<CarVariantDialogComponent>,
		private motorDataDataService: MotorDataDataService,
    	private alertService: AlertService
	) {
		this.linkId = this.data.linkId;
		this.carData = this.data.requestData;
		this.confirmChanges = false;
	}

	editCarVariant() {
		let searchText: string = this.carData.model + " " + this.carData.year;
		// let searchText: string = this.carData.model + " 2019";

		this.motorDataDataService
			.getVehicleListing(20, 1, searchText, this.carData.make)
			.subscribe({
				next: (x) => {
					if (x.length === 0) {
						this.alertService.openSnackBar("No car variant found!");
						this.editCarModel = false;
					}
					else {
						this.carVars = x;
					}
				},
				error: (err) => {
					this.alertService.openSnackBar("Error:", err);
				},
			});
	}

	proceedQuotation() {
		this.dialogRef.close({ data: true });
	}

	saveChanges(selectedCar: any) {
		// update form
		this.motorDataDataService
			.editCarVariant(this.linkId, selectedCar.id)
			.subscribe({
				next: (x) => {
					// confirm changes layout
					this.confirmChanges = true;
				},
				error: (err) => {
					this.alertService.openSnackBar(
						"Failed sending edit car variant request",
					);
					this.dialogRef.close();
				},
			});
	}

	onCarSelectChange(event: any): void {
		this.selectedCar = {id: event.value.id, vehicle: event.value.vehicle};
	}
}
