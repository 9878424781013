import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FaqDialogComponent } from "src/app/dashboard/faq-dialog/faq-dialog.component";
import { GuidesDialogComponent } from "src/app/dashboard/guides-dialog/guides-dialog.component";
import { PartnerService } from "src/app/share/service/partner.service";

@Component({
	selector: "app-menu-mobile",
	templateUrl: "./menu-mobile.component.html",
	styleUrls: ["./menu-mobile.component.scss"],
})
export class MenuMobileComponent implements OnInit {
	partnerCode: string = "carsomeCapital";

	showFAQ: Boolean = false;
	showFAQList: string[] = ["cbp"];

	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<MenuMobileComponent>,
		private partnerService: PartnerService,
	) {}

	ngOnInit(): void {
		this.getPartnerCode();
	}
	private getPartnerCode() {
		this.partnerCode = this.partnerService.getPartnerCode();
		!this.partnerCode ? (this.partnerCode = "carsomeCapital") : null;
		if (this.showFAQList.includes(this.partnerCode)) {
			this.showFAQ = true;
		}
	}

	guideDialog() {
		this.dialogRef.close();
		this.dialog.open(GuidesDialogComponent, {
			panelClass: "guide-dialog",
		});
	}

	dismissDialog() {
		this.dialogRef.close();
	}

	faqDialog() {
		this.dialogRef.close();
		this.dialog.open(FaqDialogComponent, {
			panelClass: "faq-dialog",
		});
	}
}
