<div class="benefit-content">
	<div class="title">
		{{ "common.benefits" | translate }}
	</div>
	<app-benefit
		[code]="data?.code"
		[insuranceType]="data?.insuranceType"
		*ngIf="data?.code"
	></app-benefit>
</div>

<div class="links" *ngIf="productDocumentInterface?.length">
	<a
		href=""
		*ngFor="let item of productDocumentInterface"
		[href]="item.url"
		target="_blank"
	>
		{{ item.name }}
	</a>
</div>
