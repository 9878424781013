import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import { ProductDocumentInterface } from "src/app/share/interface/motor.interface";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PartnerService } from "src/app/share/service/partner.service";

export interface DialogData {
	productId: string;
	code: string;
	lang: string;
	insuranceType: string;
}
@Component({
	selector: "app-benefit-dialog",
	templateUrl: "./benefit-dialog.component.html",
	styleUrls: ["./benefit-dialog.component.scss"],
})
export class BenefitDialogComponent {
	productDocumentInterface: ProductDocumentInterface[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DialogData,
		private motorDataDataService: MotorDataDataService,
	) {
		this.getProductDocument();
	}

	private getProductDocument() {
		if (!this.data) return;
		this.motorDataDataService
			.getProductDocumentById(
				this.data.productId,
				this.data.lang,
				this.data.insuranceType,
			)
			.subscribe({
				next: (x) => {
					this.productDocumentInterface = x;
				},
			});
	}
}
