import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-static-styles",
	templateUrl: "./static-styles.component.html",
	styleUrls: ["./static-styles.component.scss"],
})
export class StaticStylesComponent {
	constructor() {}
}
