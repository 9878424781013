<ng-container *ngIf="!quotationSubmitted; else instantQuote">
	<div class="form-container-bg">
		<div>
			<div>
				<div class="forms">
					<mat-card>
						<mat-stepper #stepper linear class="pt-md">
							<!-- Vehicle Form -->
							<mat-step [stepControl]="vehicleFormGroup">
								<form [formGroup]="vehicleFormGroup" #vehicleForm="ngForm">
									<ng-template matStepLabel>
										<span>{{ "dashboard.stepOne" | translate }}</span>
									</ng-template>

									<div class="form-wrapper">
										<mat-form-field appearance="outline">
											<mat-label>{{
												"common.typeOfInsurance" | translate
											}}</mat-label>
											<mat-select
												disableOptionCentering
												formControlName="InsuranceType"
												required
											>
												<mat-option
													*ngFor="let item of typeOfInsuranceList"
													[value]="item.id"
												>
													{{ item.name | translate }}
												</mat-option>
											</mat-select>

											<mat-icon
												class="tooltip-icon tooltip-style-1"
												[matMenuTriggerFor]="typeTooltip"
												#typeTrigger="matMenuTrigger"
												(mouseenter)="typeTrigger.openMenu()"
											>
												info
											</mat-icon>
										</mat-form-field>

										<mat-form-field appearance="outline" class="upperc-input">
											<mat-label>{{
												"common.carPlateNo" | translate
											}}</mat-label>
											<input
												matInput
												required
												formControlName="VehicleNo"
												mask="A{255}"
												[validation]="false"
												autocomplete="off"
											/>
											<mat-error
												>{{ "common.carPlateNo" | translate }}
												{{ "error.required" | translate }}</mat-error
											>
										</mat-form-field>

										<mat-form-field appearance="outline">
											<mat-label>{{
												"common.residentialPostcode" | translate
											}}</mat-label>
											<input
												matInput
												required
												formControlName="PostCode"
												mask="00000"
												autocomplete="postal-code"
											/>
											<mat-error>
												{{ "error.invalidPostcode" | translate }}
											</mat-error>
										</mat-form-field>

										<div>
											<mat-label
												>{{ "common.businessType" | translate }}:</mat-label
											>
											<div class="box-selection">
												<mat-radio-group
													class="b2c-radio"
													aria-label="Select an option"
													formControlName="BusinessType"
												>
													<mat-radio-button
														[value]="businessTypeOption.transferOwnership"
														>{{ "common.transferOwnership" | translate }}
													</mat-radio-button>
													<mat-radio-button [value]="businessTypeOption.renewal"
														>{{ "common.renewal" | translate }}
													</mat-radio-button>
												</mat-radio-group>

												<mat-radio-group
													*ngIf="
														vehicleFormGroup.get('BusinessType').value ===
														businessTypeOption.renewal
													"
													class="b2c-radio"
													aria-label="Select an option"
													formControlName="TypeOfSum"
												>
													<mat-radio-button
														[value]="typeOfSumOption.marketValue"
														>{{ "common.MV" | translate }}

														<mat-icon
															class="tooltip-icon tooltip-style-2"
															[matMenuTriggerFor]="mvTooltip"
															#mvTrigger="matMenuTrigger"
															(mouseenter)="mvTrigger.openMenu()"
														>
															info
														</mat-icon>

														<mat-menu
															#mvTooltip="matMenu"
															[overlapTrigger]="false"
															class="tooltip-popover"
														>
															<span (mouseleave)="mvTrigger.closeMenu()">
																<div class="title" style="color: #49b0ff">
																	{{ "common.MV" | translate }}:
																</div>
																{{ "common.marketValDesc" | translate }}
															</span>
														</mat-menu>
													</mat-radio-button>
													<mat-radio-button
														[value]="typeOfSumOption.agreedValue"
														>{{ "common.AV" | translate }}

														<mat-icon
															class="tooltip-icon tooltip-style-2"
															[matMenuTriggerFor]="avTooltip"
															#avTrigger="matMenuTrigger"
															(mouseenter)="avTrigger.openMenu()"
														>
															info
														</mat-icon>

														<mat-menu
															#avTooltip="matMenu"
															[overlapTrigger]="false"
															class="tooltip-popover"
														>
															<span (mouseleave)="avTrigger.closeMenu()">
																<div class="title" style="color: #49b0ff">
																	{{ "common.AV" | translate }}:
																</div>
																{{ "common.agreedValDesc" | translate }}
															</span>
														</mat-menu>
													</mat-radio-button>
												</mat-radio-group>
											</div>
										</div>
									</div>

									<button
										mat-flat-button
										matStepperNext
										[disabled]="vehicleFormGroup.invalid"
									>
										{{ "common.proceed" | translate }}
									</button>
								</form>
							</mat-step>

							<!-- Customer Form -->
							<mat-step [stepControl]="custFormGroup">
								<form
									[formGroup]="custFormGroup"
									#custForm="ngForm"
									(ngSubmit)="submitFormData()"
								>
									<div class="mdTitle-f mb-sm">Buyer’s details</div>
									<ng-template matStepLabel>
										<span>{{ "dashboard.stepTwo" | translate }}</span>
									</ng-template>

									<div class="form-wrapper">
										<mat-form-field appearance="outline">
											<mat-label>{{ "common.idType" | translate }} </mat-label>
											<mat-select formControlName="IdType">
												<mat-option
													*ngFor="let item of idTypeList"
													[value]="item.id"
												>
													{{ item.name | translate }}
												</mat-option>
											</mat-select>
										</mat-form-field>

										<!-- Details for foreigner only -->
										<mat-form-field appearance="outline" *ngIf="!isMalaysian">
											<mat-label>{{
												"common.nationality" | translate
											}}</mat-label>
											<mat-select formControlName="Nationality">
												<mat-option>
													<ngx-mat-select-search
														[placeholderLabel]="
															'common.nationalityPH' | translate
														"
														[ngModelOptions]="{ standalone: true }"
														[searching]="!countryList.length"
														noEntriesFoundLabel="{{
															'common.nationalityXrecord' | translate
														}}"
														(ngModelChange)="filterCountry($event)"
														ngModel
														ngDefaultControl
													>
													</ngx-mat-select-search>
												</mat-option>
												<mat-option
													*ngFor="let item of filterCountryList"
													[value]="item"
												>
													{{ item }}</mat-option
												>
											</mat-select>
											<mat-error
												>{{ "common.nationality" | translate }}
												{{
													custFormGroup.controls.Nationality.errors
														| inputError
														| translate
												}}</mat-error
											>
										</mat-form-field>

										<mat-form-field appearance="outline">
											<mat-label>
												{{ getIdentityNoLabel() | translate }}
											</mat-label>
											<input
												matInput
												required
												formControlName="IdentityNo"
												[mask]="getIdentityNoMasking()"
												autocomplete="off"
												[maxLength]="isMalaysian ? 12 : 99"
											/>
											<mat-error
												>{{ getIdentityNoLabel() | translate }}
												{{
													custFormGroup.controls.IdentityNo.errors
														| inputError
														| translate
												}}</mat-error
											>
										</mat-form-field>

										<ng-container *ngIf="!isCompany">
											<!-- Details for malaysian only -->
											<mat-form-field appearance="outline" *ngIf="isMalaysian">
												<mat-label>{{
													"common.policeOrArmyIcNo" | translate
												}}</mat-label>
												<input
													matInput
													autocomplete="off"
													formControlName="PolicIdentityNo"
												/>
											</mat-form-field>

											<mat-form-field appearance="outline">
												<mat-label>{{ getNameLabel() | translate }}</mat-label>
												<input
													required
													matInput
													autocomplete="name"
													formControlName="Name"
												/>
												<mat-error>
													{{ getNameLabel() | translate }}
													{{
														custFormGroup.controls.Name.errors
															| inputError
															| translate
													}}
												</mat-error>
											</mat-form-field>

											<mat-form-field appearance="outline">
												<mat-label>{{ "common.dob" | translate }}</mat-label>
												<input
													matInput
													[matDatepicker]="picker"
													formControlName="Dob"
													readonly
													(click)="picker.open()"
													[max]="maxDob"
												/>
												<mat-datepicker-toggle
													matSuffix
													[for]="picker"
												></mat-datepicker-toggle>
												<mat-datepicker #picker></mat-datepicker>
												<mat-error>
													{{ "common.dob" | translate }}
													{{
														custFormGroup.controls.Dob.errors
															| inputError
															| translate
													}}
												</mat-error>
											</mat-form-field>

											<mat-form-field appearance="outline" *ngIf="isMalaysian">
												<mat-label
													>{{ "common.gender" | translate }}
												</mat-label>
												<mat-select formControlName="Gender">
													<mat-option [value]="genderOption.male">{{
														"common.M" | translate
													}}</mat-option>
													<mat-option [value]="genderOption.female">{{
														"common.F" | translate
													}}</mat-option>
												</mat-select>
											</mat-form-field>

											<div class="box-selection">
												<div *ngIf="!isMalaysian">
													<mat-label>{{
														"common.gender" | translate
													}}</mat-label>
													<mat-radio-group
														class="b2c-radio"
														aria-label="Select an option"
														formControlName="Gender"
													>
														<mat-radio-button [value]="genderOption.male"
															>{{ "common.M" | translate }}
														</mat-radio-button>
														<mat-radio-button [value]="genderOption.female"
															>{{ "common.F" | translate }}
														</mat-radio-button>
													</mat-radio-group>
												</div>

												<div>
													<mat-label>{{
														"common.maritalStatus" | translate
													}}</mat-label>
													<mat-radio-group
														class="b2c-radio"
														aria-label="Select an option"
														formControlName="MaritalStatus"
													>
														<mat-radio-button
															[value]="maritalStatusOption.single"
															>{{ "common.Single" | translate }}
														</mat-radio-button>
														<mat-radio-button
															[value]="maritalStatusOption.married"
															>{{ "common.Married" | translate }}
														</mat-radio-button>
													</mat-radio-group>
												</div>
											</div>
										</ng-container>
									</div>

									<div class="mdTitle-f mb-sm">Agent's details</div>

									<div class="form-wrapper">
										<mat-form-field appearance="outline">
											<mat-label>{{ "common.mobileNo" | translate }}</mat-label>
											<ngx-mat-intl-tel-input
												[enableSearch]="true"
												[preferredCountries]="['my']"
												formControlName="AgentMobileNo"
											>
											</ngx-mat-intl-tel-input>
											<mat-error
												>{{ "common.mobileNo" | translate }}
												{{
													custFormGroup.controls.AgentMobileNo.errors
														| inputError
														| translate
												}}</mat-error
											>
										</mat-form-field>

										<mat-form-field appearance="outline">
											<mat-label>{{ "common.email" | translate }}</mat-label>
											<input
												matInput
												formControlName="AgentEmail"
												autocomplete="email"
											/>
											<mat-error
												[hidden]="
													!custFormGroup.controls.AgentEmail?.errors?.AgentEmail
												"
											>
												{{ "error.invalidEmail" | translate }}</mat-error
											>
										</mat-form-field>
									</div>

									<section class="mt-sm">
										<mat-checkbox
											class="checkbox-align-top"
											formControlName="pdpaAgreement"
											(change)="checkAgreement()"
										>
											{{ "common.pdpa" | translate }}

											<a
												[href]="'common.pdpaHref' | translate"
												target="blank"
												style="text-decoration: none"
											>
												<span class="color-primary">
													{{ "common.pdpaLink" | translate }}
												</span>
											</a>
										</mat-checkbox>
									</section>

									<button
										mat-flat-button
										[disabled]="
											vehicleFormGroup.invalid || custFormGroup.invalid
										"
										*ngIf="
											vehicleFormGroup.controls['BusinessType'].value ===
												businessTypeOption.transferOwnership ||
											[4, 5].includes(custFormGroup.get('IdType').value)
										"
										matStepperNext
										type="button"
									>
										{{ "common.proceed" | translate }}
									</button>
									<button
										mat-flat-button
										[disabled]="
											vehicleFormGroup.invalid || custFormGroup.invalid
										"
										*ngIf="
											vehicleFormGroup.controls['BusinessType'].value ===
												businessTypeOption.renewal &&
											[1, 2, 3].includes(custFormGroup.get('IdType').value)
										"
										type="submit"
									>
										{{ "common.proceed" | translate }}
									</button>
								</form>
							</mat-step>

							<!-- More Details Form -->
							<mat-step
								[stepControl]="moreDetailsFormGroup"
								class="third"
								*ngIf="
									vehicleFormGroup.get('BusinessType').value ===
										businessTypeOption.transferOwnership ||
									[4, 5].includes(custFormGroup.get('IdType').value)
								"
							>
								<form
									[formGroup]="moreDetailsFormGroup"
									(ngSubmit)="submitFormData()"
								>
									<ng-template matStepLabel>
										<span>{{ "dashboard.stepThree" | translate }}</span>
									</ng-template>

									<div
										*ngIf="
											vehicleFormGroup.get('BusinessType').value !==
											businessTypeOption.renewal
										"
										class="form-wrapper"
									>
										<mat-form-field appearance="outline">
											<mat-label
												>{{ "common.previousNCD" | translate }} %</mat-label
											>
											<mat-select formControlName="PreviousNCD">
												<mat-option [value]="item" *ngFor="let item of ncdList"
													>{{ item * 100 | number : "1.2-2" }}%
												</mat-option>
											</mat-select>
										</mat-form-field>

										<mat-form-field appearance="outline" class="upperc-input">
											<mat-label>{{
												"common.prevCarPlate" | translate
											}}</mat-label>
											<input
												matInput
												formControlName="PreviousVehicleNo"
												mask="A{255}"
												[validation]="false"
												autocomplete="off"
											/>
										</mat-form-field>

										<div>
											<mat-label
												>{{ "common.sumInsured" | translate }}:
											</mat-label>

											<mat-radio-group
												class="b2c-radio"
												aria-label="Select an option"
												formControlName="SumInsuredType"
											>
												<mat-radio-button
													[value]="sumInsuredTypeOption.recommended"
													>{{ "dashboard.recommended" | translate }}
												</mat-radio-button>
												<mat-radio-button [value]="sumInsuredTypeOption.other"
													>{{ "dashboard.otherAmount" | translate }}
												</mat-radio-button>
											</mat-radio-group>
										</div>

										<div
											*ngIf="
												moreDetailsFormGroup.get('SumInsuredType').value ===
												sumInsuredTypeOption.other
											"
										>
											<mat-form-field appearance="outline">
												<mat-label
													>{{ "dashboard.amount" | translate }} (RM)</mat-label
												>
												<input
													matInput
													autocomplete="off"
													formControlName="SumInsured"
													mask="separator.2"
													thousandSeparator=","
													autocomplete="off"
													separatorLimit="99999999999999"
												/>
											</mat-form-field>

											<span class="sum-insured__note">{{
												"dashboard.sumInsuredNote" | translate
											}}</span>
										</div>
									</div>

									<div class="upload-car-grant">
										<div>
											<input
												type="file"
												[accept]="acceptFile"
												name="carGrant"
												id="carGrant"
												(change)="handleFileInput($event)"
												style="display: none"
											/>
											<div class="mdDesc-f bold">
												{{
													(vehicleFormGroup.get("BusinessType").value ===
													businessTypeOption.transferOwnership
														? "dashboard.uploadNewCarGrant"
														: "dashboard.uploadCarGrant"
													) | translate
												}}
											</div>

											<button mat-stroked-button type="button">
												<label for="carGrant" class="upload-button">
													<mat-icon color="primary">add_circle</mat-icon
													>{{ "common.upload" | translate }}
												</label>
											</button>
										</div>

										<div *ngIf="moreDetailsFormGroup.get('VehicleGrant').value">
											<div>
												<span class="underlined-blue">
													{{
														moreDetailsFormGroup.get("VehicleGrant").value.name
													}}
												</span>
												<button mat-icon-button (click)="deleteCarGrantFile()">
													<mat-icon>cancel</mat-icon>
												</button>
											</div>
											<div class="uploaded-status">
												<img src="/assets/image/tick-icon.png" />
												<div class="smTitle-f color-dark-green">
													{{ "common.completed" | translate }}
												</div>
											</div>
										</div>
									</div>

									<button
										mat-flat-button
										[disabled]="
											vehicleFormGroup.invalid ||
											custFormGroup.invalid ||
											moreDetailsFormGroup.invalid
										"
									>
										{{ "common.proceed" | translate }}
									</button>
								</form>
							</mat-step>
						</mat-stepper>

						<div
							*ngIf="stepper.selectedIndex === 1"
							class="accept-payment-graphic"
						>
							<span>{{ "dashboard.weAccept" | translate }} :</span>
							<img src="assets/image/accepted-payment.png" alt="" />
						</div>
					</mat-card>
				</div>
				<div
					fxFlex
					class="copy-writing"
					fxLayout="column"
					fxLayoutAlign="space-between"
				>
					<div class="title">
						{{ "dashboard.desc" | translate }}
					</div>

					<div class="desc">
						Powered by
						<img src="/assets/image/ps-logo-white.png" alt="" class="ps-logo" />
					</div>
				</div>
				<div class="menu-space">
					<!-- <img
					(click)="whatsppRedirect()"
					class="whatsapp-btn"
					src="assets/image/whatsapp-icon.png"
					alt=""
					*ngIf="partnerCode !== 'carsome'"
				/> -->
				</div>
			</div>
		</div>
	</div>

	<mat-menu
		#typeTooltip="matMenu"
		[overlapTrigger]="false"
		class="tooltip-popover"
	>
		<span (mouseleave)="typeTrigger.closeMenu()">
			<div class="title">
				{{ "common.TPFT" | translate }}
			</div>
			{{ "common.fireTheftInsDesc" | translate }}
			<br />
			<br />
			<div class="title">
				{{ "common.COM" | translate }}
			</div>
			{{ "common.comprehensiveInsDesc" | translate }}
		</span>
	</mat-menu>
</ng-container>

<ng-template #instantQuote>
	<app-loading-quotation [formData]="quotationFormData"></app-loading-quotation>
</ng-template>
