import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: "app-insurer-details",
	templateUrl: "./insurer-details.component.html",
	styleUrls: ["./insurer-details.component.scss"],
})
export class InsurerDetailsComponent {
	_code: string;
	_first: boolean;
	_insuranceType: string;
	params: string;

	constructor() {}

	get code(): string {
		return this._code;
	}

	@Input() set code(value: string) {
		this._code = value;
	}

	get first(): boolean {
		return this._first;
	}

	@Input() set first(value: boolean) {
		this._first = value;
	}

	get insuranceType(): string {
		return this._insuranceType;
	}
	@Input() set insuranceType(value: string) {
		this._insuranceType = value;
	}
}
