import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import { FileFunction } from "src/app/share/function/file.function";
import { AlertService } from "src/app/share/service/alert.service";
import { ThankyouDialogComponent } from "../thankyou-dialog/thankyou-dialog.component";
import { SampleDialogComponent } from "./sample-dialog/sample-dialog.component";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
	selector: "app-upload-documents",
	templateUrl: "./upload-documents.component.html",
	styleUrls: ["./upload-documents.component.scss"],
})
export class UploadDocumentsComponent {
	fileToUpload: any[] = [];
	fileToShow = {
		ID: [],
		LOU: [],
		VehicleGrant: [],
		FrontCar: [],
		BackCar: [],
		LeftCar: [],
		RightCar: [],
	};

	id: string;

	data: {}[];

	errorActivatedPolicy: boolean = false;

	constructor(
		private dialog: MatDialog,
		private motorDataDataService: MotorDataDataService,
		private activatedRoute: ActivatedRoute,
		private alertService: AlertService,
		private ngxSpinnerService: NgxSpinnerService,
	) {
		this.id = this.activatedRoute.snapshot.params.id;
		this.getData();
	}

	private getData() {
		this.motorDataDataService.getPolicyPhotos(this.id).subscribe({
			next: (data: any) => {
				data.forEach((data) => {
					this.fileToShow[data.result.refType].push(data.result); // Add new entry
				});
				this.ngxSpinnerService.hide();
			},
			error: (err) => {
			},
		});
	}

	showSampleDialog(data: string) {
		this.dialog.open(SampleDialogComponent, {
			data: data,
			panelClass: "success-dialog",
		});
	}

	deleteFile(type, item: any) {
		if (item?.id){
			this.motorDataDataService.deletePolicyPhoto(item.id).subscribe({
				next: (x: any) => {
				},
				error: (err) => {
					this.alertService.openSnackBar(err.error);
				},
			});
		}
		
		if (item?.id){
			this.fileToShow[type] = this.fileToShow[type].filter(
				(value) => value?.id == null || value?.id != item.id,
			);
		}
		else {
			this.fileToUpload = this.fileToUpload.filter((value) => value.file != item?.file && value.index != item?.index);
			this.fileToShow[type] = this.fileToShow[type].filter(
				(value) => (value.file != item?.file && value.index != item?.index) || value?.id != null,
			);
		}
	}

	handleSubmit() {
		if (this.fileToUpload) {
			let fd = new FormData();
			for (let i = 0; i < this.fileToUpload.length; i++) {
				fd.append(`fileList[${i}].file`, this.fileToUpload[i].file);
				fd.append(`fileList[${i}].type`, this.fileToUpload[i].type);
			}

			this.motorDataDataService.uploadPhoto(this.id, fd).subscribe({
				next: (x: any) => {
					this.fileToUpload = [];
					this.fileToShow = {
						ID: [],
						LOU: [],
						VehicleGrant: [],
						FrontCar: [],
						BackCar: [],
						LeftCar: [],
						RightCar: [],
					};
					this.getData();
					this.dialog.open(ThankyouDialogComponent, {
						panelClass: "success-dialog",
						data: {
							title: "Thank you",
							body: "uploadDocs.thankyouMsg",
						},
					});
				},
				error: (err) => {
					this.alertService.openSnackBar(err.error);
				},
			});
		}
	}

	handleFileInput(e) {
		const files = e.target.files;
		if (files.length) {
			for (let i = 0; i < files.length; i++) {
				let file: File = files[i];
				if (!FileFunction.isImageOrPdf(file)) {
					this.alertService.openSnackBar(
						`Fail to upload ${file.name} - just accept image type (jpeg, png & svg)`,
					);
					return;
				}
				this.fileToUpload.push({
					type: e.target.name,
					file: file,
					index: i
				});

				this.fileToShow[e.target.name].push({
					type: e.target.name,
					file: file,
					index: i
				});
			}
		}

		e.target.value = null;
	}
}
