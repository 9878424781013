<div>
	<mat-dialog-content>
		<div class="mdTitle-f">
			{{ "common.agentCodeDesc" | translate }}
		</div>
		<mat-form-field class="input-field full-width mv-sm">
			<mat-label> {{ "common.agentCode" | translate }}</mat-label>
			<input type="text" matInput [(ngModel)]="agentCode" />
		</mat-form-field>
		<div fxLayout="row" fxLayoutAlign="space-between">
			<div></div>
			<div>
				<button mat-flat-button mat-dialog-close class="mr-sm">
					{{ "common.cancel" | translate }}
				</button>
				<button mat-flat-button color="primary" (click)="submitAgentCode()">
					{{ "common.confirm" | translate }}
				</button>
			</div>
		</div>
	</mat-dialog-content>
</div>
