import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-faq-dialog",
	templateUrl: "./faq-dialog.component.html",
	styleUrls: ["./faq-dialog.component.scss"],
})
export class FaqDialogComponent {
	currentLang: string;
	constructor(private translate: TranslateService) {}

	switch(lang: string) {
		this.translate.use(lang);
	}
}
