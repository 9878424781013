<div class="thankyou-purchase-dialog">
	<mat-dialog-content>
		<div fxLayout="column" fxLayoutAlign="center center">
			<img src="assets/image/purchase-graphic.png" alt="" class="graphic" />
			<div
				class="mdTitle-f mt-sm"
				color="primary"
				fxLayout="row"
				fxLayoutAlign="center center"
			>
				{{ "purchase.title" | translate }}
				<img
					src="assets/image/tick-icon.png"
					alt=""
					style="width: 20px; margin-left: 10px"
				/>
			</div>
			<div class="mv-md">
				{{ "purchase.desc" | translate }}
			</div>
			<button
				mat-flat-button
				mat-dialog-close
				color="primary"
				class="full-width"
			>
				{{ "success.close" | translate }}
			</button>
		</div>
	</mat-dialog-content>
</div>
