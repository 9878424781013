import { Overlay } from "@angular/cdk/overlay";
import { ValidatorFunction } from "./../../share/function/validator.function";
import { AlertService } from "./../../share/service/alert.service";
import { MasterDataDataService } from "./../../share/data-service/master-data.data.service";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	NgForm,
	ValidationErrors,
	Validators
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { MatStepper } from "@angular/material/stepper";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { PartnerService } from "src/app/share/service/partner.service";
import {
	BUSINESS_TYPE,
	GENDER_TYPE,
	ID_TYPE,
	INSURED_TYPE,
	MARITAL_STATUS,
	SUM_INSURED_TYPE
} from "src/app/share/constants/common.types";
import { TransferOwnershipDataService } from "src/app/share/data-service/transfer-ownership.data.service";
import { SubmitSuccessDialogComponent } from "../submit-success-dialog/submit-success-dialog.component";
import { SubmitFailDialogComponent } from "../submit-fail-dialog/submit-fail-dialog.component";
import { BeforeExpiredDialogComponent } from "../before-expired-dialog/before-expired-dialog.component";
import { Subscription } from "rxjs";
import { FileService } from "src/app/share/service/file.service";
import * as moment from "moment";

@Component({
	selector: "app-dashboard",
	templateUrl: "./dashboard.component.html",
	styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit, OnDestroy {
	@ViewChild("stepper") stepper: MatStepper;

	quotationSubmitted: boolean = false;
	quotationFormData: FormData = null;
	partnerCode: string;

	@ViewChild("vehicleForm") vehicleForm: NgForm;
	@ViewChild("custForm") custForm: NgForm;
	vehicleFormGroup: FormGroup;
	custFormGroup: FormGroup;
	moreDetailsFormGroup: FormGroup;

	countryList: string[] = [];
	ncdList: number[] = [];
	filterCountryList: string[] = [];
	isCompany: boolean = false;
	isMalaysian: boolean = true;

	idTypeOption = ID_TYPE;
	maritalStatusOption = MARITAL_STATUS;
	genderOption = GENDER_TYPE;
	businessTypeOption = BUSINESS_TYPE;
	sumInsuredTypeOption = SUM_INSURED_TYPE;
	typeOfSumOption = INSURED_TYPE;

	utmCampaign: string;
	utmSource: string;
	utmMedium: string;
	utmContent: string;

	maxDob: Date = new Date();
	validPostcodes: string[] = [];

	idTypeList: OptionData[] = [
		{ id: 1, name: "common.malaysian" },
		{ id: 2, name: "common.foreigner" },
		{ id: 3, name: "common.company" },
		{ id: 4, name: "common.commercialInd" },
		{ id: 5, name: "common.commercialComp" }
	];

	typeOfInsuranceList: OptionData[] = [
		{ id: "TPFT", name: "common.TPFT" },
		{ id: "COM", name: "common.COM" }
	];

	acceptFile: string = ".pdf,.png,.jpg,.jpeg";
	maxLength: number = 1; // Maximum number of files
	fileSize: number = 10; // Maximum file size
	fileList: File[] | any = [];

	subscriptions: Subscription[] = [];

	constructor(
		gtmService: GoogleTagManagerService,
		public overlay: Overlay,
		private formBuilder: FormBuilder,
		private translate: TranslateService,
		private dialog: MatDialog,
		private alertService: AlertService,
		private ngxSpinnerService: NgxSpinnerService,
		private activatedRoute: ActivatedRoute,
		private partnerService: PartnerService,
		private fileService: FileService,
		private masterDataDataService: MasterDataDataService,
		private transferOwnershipDataService: TransferOwnershipDataService
	) {
		gtmService.pushTag({
			event: "car_lp"
		});
		this.getParams();
		this.initForm();
		this.listenChanges();
	}

	ngOnInit(): void {
		// Trigger subsciption to detect valuechanges
		this.custFormGroup.get("IdType").patchValue(this.idTypeList[0].id);

		this.masterDataDataService.selectAllPostcodes().subscribe({
			next: (x) => {
				this.validPostcodes = x;
			},
			error: (err) => {
				this.alertService.openSnackBar(err.error);
			}
		});
	}

	private getParams(): void {
		let snapshotParam = this.activatedRoute.snapshot.queryParams;
		let lang: string = snapshotParam.lang;
		if (lang) {
			if (lang.toLocaleLowerCase() == "bm") {
				this.translate.use("bm");
			}
		}

		this.partnerCode = this.partnerService.getPartnerCode();
		!this.partnerCode ? (this.partnerCode = "carsomeCapital") : null;

		this.utmSource = snapshotParam.utm_source;
		this.utmMedium = snapshotParam.utm_medium;
		this.utmCampaign = snapshotParam.utm_campaign;
		this.utmContent = snapshotParam.utm_content;

		if (!this.utmSource) {
			let searchEngineList = ["google", "yahoo", "bing"];
			let referrer = document.referrer;
			if (
				referrer != window.location.href ||
				referrer.includes("policystreet.com")
			) {
				let searchEngine = searchEngineList.find((x) =>
					referrer.toLowerCase().includes(x)
				);
				if (searchEngine) {
					this.utmSource = searchEngine;
					this.utmMedium = "organic";
				} else {
					if (!referrer) this.utmSource = "direct";
					else {
						let url = new URL(referrer);
						this.utmSource = url.hostname;
						this.utmMedium = "referral";
					}
				}
			} else {
				this.utmSource = "direct";
			}
		}
	}

	private initForm(): void {
		this.vehicleFormGroup = this.formBuilder.group({
			InsuranceType: ["COM", Validators.required],
			VehicleNo: [null, Validators.required],
			PostCode: [
				null,
				[
					Validators.required,
					(control: FormControl) => this.postcodeValidator(control)
				]
			],
			BusinessType: [
				this.businessTypeOption.transferOwnership,
				Validators.required
			],
			TypeOfSum: ["MV"]
		});

		this.custFormGroup = this.formBuilder.group({
			IdType: [null],
			IdentityNo: [null, Validators.required],
			Name: [null, Validators.required],
			PolicIdentityNo: [null],
			Nationality: [null, Validators.required],
			Dob: [null, Validators.required],
			Gender: [null, Validators.required],
			MaritalStatus: [this.maritalStatusOption.single, Validators.required],
			AgentMobileNo: [null, Validators.required],
			AgentEmail: [null, [Validators.required, Validators.email]],
			IsCommercialIndividual: [false],
			IsCommercialCompany: [false],
			pdpaAgreement: [false, Validators.requiredTrue]
		});

		this.moreDetailsFormGroup = this.formBuilder.group({
			PreviousNCD: [null, Validators.required],
			PreviousVehicleNo: [null, Validators.required],
			SumInsuredType: [this.sumInsuredTypeOption.recommended],
			SumInsured: [null],
			VehicleGrant: [null, Validators.required]
		});
	}

	listenChanges(): void {
		this.subscriptions.push(
			this.custFormGroup.get("IdentityNo").valueChanges.subscribe((value) => {
				if (this.custFormGroup.get("IdentityNo").invalid) {
					["Dob", "Gender"].forEach((key) => {
						this.custFormGroup.get(key).reset();
					});
				}

				if (
					this.custFormGroup.get("IdentityNo").valid &&
					this.isMalaysian &&
					!this.isCompany
				) {
					let date = moment(value.substring(0, 6), "YYMMDD");

					let lastChar = value.substring(11);
					let gender =
						lastChar % 2 ? this.genderOption.male : this.genderOption.female;

					this.custFormGroup.patchValue({
						Dob: date.toDate(),
						Gender: gender
					});
				}
			}),

			this.custFormGroup.get("IdType").valueChanges.subscribe((value) => {
				switch (value) {
					case 1:
					case 4:
						this.setMalaysian();
						break;

					case 2:
						this.setNonMalaysian();
						break;

					case 3:
					case 5:
						this.setIsCompany();
						break;
				}

				this.custFormGroup.patchValue({
					IsCommercialIndividual: value === 4 ? true : false,
					IsCommercialCompany: value === 5 ? true : false
				});

				if ([4, 5].includes(value)) {
					this.ncdList = this.masterDataDataService.getCommercialNcdList();
				} else {
					this.ncdList = this.masterDataDataService.getNcdList();
				}
			}),

			this.moreDetailsFormGroup
				.get("SumInsuredType")
				.valueChanges.subscribe((value) => {
					const sumInsured = this.moreDetailsFormGroup.get("SumInsured");

					if (value === this.sumInsuredTypeOption.recommended) {
						sumInsured.clearValidators();
						sumInsured.reset();
					} else {
						sumInsured.setValidators(Validators.required);
					}

					sumInsured.updateValueAndValidity();
				}),

			this.vehicleFormGroup
				.get("BusinessType")
				.valueChanges.subscribe((value) => {
					if (value === this.businessTypeOption.renewal) {
						this.vehicleFormGroup
							.get("TypeOfSum")
							.patchValue(this.typeOfSumOption.agreedValue);

						["PreviousNCD", "PreviousVehicleNo", "SumInsuredType"].forEach(
							(key) => {
								this.moreDetailsFormGroup.get(key).clearValidators();
							}
						);
					} else {
						["PreviousNCD", "PreviousVehicleNo", "SumInsuredType"].forEach(
							(key) => {
								this.moreDetailsFormGroup
									.get(key)
									.setValidators(Validators.required);
							}
						);
					}

					this.moreDetailsFormGroup.updateValueAndValidity();
				})
		);
	}

	private setMalaysian(): void {
		this.isCompany = false;
		this.isMalaysian = true;

		this.custFormGroup
			.get("IdentityNo")
			.setValidators([Validators.required, ValidatorFunction.nricValidator()]);
		this.custFormGroup.get("Name").setValidators(Validators.required);
		this.custFormGroup.get("Nationality").clearValidators();

		this.custFormGroup.patchValue({
			IdentityNo: null,
			Nationality: this.idTypeOption.malaysian
		});

		["Dob", "Gender"].forEach((key) => {
			this.custFormGroup.get(key).disable();
		});

		["IdentityNo", "Nationality", "Name"].forEach((key) => {
			this.custFormGroup.get(key).updateValueAndValidity();
		});

		this.setNonCompanyValidation();
	}

	private setNonMalaysian(): void {
		this.isCompany = false;
		this.isMalaysian = false;

		this.getCountryList();

		this.custFormGroup.get("IdentityNo").reset();

		["Dob", "Gender"].forEach((key) => {
			this.custFormGroup.get(key).enable();
		});

		["IdentityNo", "Nationality", "Name"].forEach((key) => {
			this.custFormGroup.get(key).setValidators(Validators.required);
			this.custFormGroup.get(key).updateValueAndValidity();
		});

		this.setNonCompanyValidation();
	}

	private setIsCompany(): void {
		this.isCompany = true;
		this.isMalaysian = true;

		this.custFormGroup.get("IdentityNo").setValidators(Validators.required);
		this.custFormGroup.get("Name").clearValidators();

		this.custFormGroup.get("IdentityNo").reset();

		["IdentityNo", "Name"].forEach((key) => {
			this.custFormGroup.get(key).updateValueAndValidity();
		});

		this.setCompanyValidation();
	}

	private setNonCompanyValidation(): void {
		["Dob", "Gender", "MaritalStatus"].forEach((key) => {
			this.custFormGroup.get(key).setValidators(Validators.required);
			this.custFormGroup.get(key).updateValueAndValidity();
		});
	}

	private setCompanyValidation(): void {
		["Dob", "Gender", "MaritalStatus", "Nationality"].forEach((key) => {
			this.custFormGroup.get(key).clearValidators();
			this.custFormGroup.get(key).updateValueAndValidity();
		});
	}

	private postcodeValidator(control: FormControl): ValidationErrors | null {
		if (control.value === null || control.value.length !== 5) return null;

		return this.validPostcodes.includes(control.value)
			? null
			: { customError: true };
	}

	private getCountryList(): void {
		if (this.countryList.length !== 0) {
			this.filterCountry();
			return;
		}

		this.masterDataDataService.getCountrySelection().subscribe({
			next: (x) => {
				const exclude = ['Iran', 'Syria', 'North Korea', 'Sudan', 'Crimea', 'Venezuela', 'Cuba', 'Israel', 'Russian Federation', 'Ukraine'];
				this.countryList = x.filter(country => !exclude.includes(country));
				this.filterCountry();
			},
			error: (err) => {
				this.alertService.openSnackBar(err.error);
			}
		});
	}

	getIdentityNoLabel(): string {
		let identityLabel: string;

		if (this.isMalaysian && !this.isCompany) {
			identityLabel = "common.icNoLabel";
		} else if (!this.isMalaysian) {
			identityLabel = "common.passportLabel";
		} else {
			identityLabel = "common.businessRegistrationNoLabel";
		}

		return identityLabel;
	}

	getNameLabel(): string {
		let nameLabel: string;

		if (this.isMalaysian && !this.isCompany) {
			nameLabel = "common.namePerIc";
		} else if (!this.isMalaysian) {
			nameLabel = "common.namePerPassport";
		} else {
			nameLabel = "common.companyName";
		}

		return nameLabel;
	}

	getIdentityNoMasking(): string {
		if (this.isMalaysian && !this.isCompany) return "000000000000";
		return null;
	}

	filterCountry(val: string = null): void {
		if (val) {
			let txt = val.trim().toLowerCase();
			this.filterCountryList = this.countryList.filter((x) =>
				x.trim().toLowerCase().startsWith(txt)
			);
		} else {
			this.filterCountryList = Object.assign([], this.countryList);
		}
	}

	checkAgreement(): void {
		let value = this.custFormGroup.get("pdpaAgreement").value;
		value = !value;
	}

	submitFormData(): void {
		if (this.vehicleFormGroup.invalid || this.custFormGroup.invalid) return;

		this.quotationFormData = this.appendFormData();

		if (
			this.vehicleFormGroup.get("BusinessType").value ===
				this.businessTypeOption.transferOwnership ||
			(this.vehicleFormGroup.get("BusinessType").value ===
				this.businessTypeOption.renewal &&
				this.vehicleFormGroup.get("TypeOfSum").value ===
					this.typeOfSumOption.agreedValue)
		) {
			this.ngxSpinnerService.show();
			this.transferOwnershipDataService
				.submit(this.quotationFormData)
				.subscribe({
					next: (x) => {
						this.ngxSpinnerService.hide();

						if (x.isSuccess) {
							this.dialog.open(SubmitSuccessDialogComponent, {
								panelClass: "success-dialog",
								data: {
									businessType: this.vehicleFormGroup.value.BusinessType,
									email: this.custFormGroup.value.AgentEmail
								}
							});
						} else {
							if (x.isVehicleOverage) {
								this.dialog.open(SubmitFailDialogComponent, {
									data: {
										isWithEHailing: x.isEhailing
									},
									panelClass: "fail-dialog"
								});
							} else {
								this.dialog.open(BeforeExpiredDialogComponent, {
									panelClass: "",
									data: {
										businessType: this.vehicleFormGroup.value.BusinessType,
										email: this.custFormGroup.value.AgentEmail
									}
								});
							}
						}

						this.resetForm();
					},
					error: (_err) => {
						this.ngxSpinnerService.hide();
						this.alertService.openSnackBar("Please contact policy street.");
					}
				});
		} else {
			this.quotationSubmitted = true;
		}
	}

	private appendFormData() {
		let fd: any = new FormData();

		// Loop Step 1 Form
		for (const [key, value] of Object.entries(this.vehicleFormGroup.value)) {
			fd.append(key, value);
		}

		// Loop Step 2 Form
		for (const [key, value] of Object.entries(
			this.custFormGroup.getRawValue()
		) as [string, any][]) {
			if (value === null || value === undefined || value === "") continue;

			if (key === "Dob") {
				fd.append(key, value.toISOString());
			} else {
				fd.append(key, value);
			}
		}

		// If Transfer Ownership or Commercial Vehicle
		if (
			this.vehicleFormGroup.get("BusinessType").value ===
				this.businessTypeOption.transferOwnership ||
			[4, 5].includes(this.custFormGroup.get("IdType").value)
		) {
			if (this.moreDetailsFormGroup.invalid) return;
			// Loop Step 3 Form
			for (const [key, value] of Object.entries(
				this.moreDetailsFormGroup.getRawValue()
			)) {
				if (value === null || value === undefined || value === "") continue;

				fd.append(key, value);
			}
		}

		fd.append("IsMalaysian", this.isMalaysian);
		fd.append("IsCompany", this.isCompany);
		fd.append("Language", this.translate.currentLang);

		return fd;
	}

	whatsppRedirect(): void {
		const whatsappNumber = 60182822320;
		const entityName = "Policystreet";
		let whatsappContent = "";

		let lang = this.translate.currentLang == "en" ? "en" : "ms";
		whatsappContent =
			lang == "ms"
				? `Selamat datang ke ${entityName}! Bagaimana kami boleh bantu anda?
    1. Saya perlukan maklumat lanjut tentang polisi insurans tertentu.
    2. Insurans Motor - Tiada maklumat kereta ditemui.
    3. Insurans Motor - Jumlah Diinsuranskan.
    4. Insurans Motor – Pilihan syarikat insurans lain.
    5. Berbincang dengan ejen ${entityName}.
    Sila taip 1-5 berdasarkan permintaan di atas untuk mula.
    `
				: `Welcome to ${entityName}! How may we help you?
      1. I need more info on a particular insurance policy.
      2. Motor insurance - car info not found.
      3. Motor insurance - sum insured.
      4. Motor insurance - other insurer choices.
      5. Talk to a ${entityName} agent.
      Please type 1-5 based on the request above to start.`;

		let url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
			whatsappContent
		)}&source=&data=&app_absent=&lang=${lang}`;

		window.open(url, "_blank");
	}

	async handleFileInput(e: any): Promise<void> {
		const files = (e.target as HTMLInputElement).files;
		const fileType = (e.target as HTMLInputElement).name;

		if (files.length === 0) return;

		for (let file of Array.from(files)) {
			const isUploadedFilesValid = await this.fileService.isUploadedFilesValid({
				file: file,
				files: files,
				currLength: this.fileList.length,
				maxLength: this.maxLength,
				accept: this.acceptFile,
				name: fileType,
				size: this.fileSize
			});

			if (!isUploadedFilesValid) {
				e.target.value = null;
				return;
			}

			this.moreDetailsFormGroup.get("VehicleGrant").patchValue(file);
		}

		e.target.value = null;
	}

	deleteCarGrantFile(): void {
		this.moreDetailsFormGroup.get("VehicleGrant").reset();
	}

	private resetForm(): void {
		this.vehicleFormGroup.reset();
		this.custFormGroup.reset();
		this.moreDetailsFormGroup.reset();

		this.vehicleForm.resetForm();
		this.custForm.resetForm();
		this.stepper.reset();

		this.initForm();
		this.listenChanges();

		// Manually reset value for ngx-mat-intl-tel-input
		// Re-trigger subscription to detect valuechanges for IdType
		this.custFormGroup.patchValue({
			AgentMobileNo: " ",
			IdType: this.idTypeList[0].id
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => {
			if (subscription && !subscription.closed) {
				subscription.unsubscribe();
			}
		});
	}
}

interface OptionData {
	id: string | number;
	name: string;
}
