import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/material-module";
import { InsurerDetailsComponent } from "./insurer-details.component";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
	exports: [InsurerDetailsComponent],
	declarations: [InsurerDetailsComponent],
	imports: [TranslateModule, CommonModule, MaterialModule, FlexLayoutModule],
})
export class InsurerDetailsModule {}
