<div class="sidefix-menu">
	<button mat-raised-button (click)="faqDialog()" class="faq" *ngIf="showFAQ">
		<img
			src="assets/icons/faq_icon_unfiltered.png"
			alt=""
			class="icon-filter"
		/>
		{{ "floatingMenu.faq" | translate }}
	</button>
	<button mat-raised-button (click)="guideDialog()" class="guides">
		<img
			src="assets/icons/guides_icon_unfiltered.png"
			alt=""
			class="icon-filter"
		/>
		{{ "floatingMenu.guide" | translate }}
	</button>
	<button mat-raised-button [matMenuTriggerFor]="language" class="language">
		<img
			src="assets/icons/language_icon_unfiltered.png"
			alt=""
			class="icon-filter"
		/>
		{{ "language." + translate.currentLang | translate }}
	</button>
	<mat-menu #language="matMenu">
		<button mat-menu-item (click)="switch('en')">
			<span>{{ "language.en" | translate }}</span>
		</button>
		<button mat-menu-item (click)="switch('bm')">
			<span>{{ "language.bm" | translate }}</span>
		</button>
	</mat-menu>
</div>
