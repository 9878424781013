import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: "root"
})
export class MasterDataDataService {
	private basePath: string = `${environment.url}/api/Microsite/`;

	constructor(private http: HttpClient) {}

	getNcdList(): number[] {
		return [0, 0.15, 0.2, 0.25, 0.3, 0.3833, 0.45, 0.55];
	}

	getCommercialNcdList(): number[] {
		return [0, 0.15, 0.2, 0.25];
	}

	getCountrySelection() {
		return this.http.get<string[]>(`${this.basePath}GetCountrySelection`);
	}

	selectAllPostcodes() {
		return this.http.get<string[]>(`${this.basePath}SelectAllPostcodes`);
	}
}
