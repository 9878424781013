import { Injectable } from "@angular/core";
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { PartnerService } from "./partner.service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	constructor(private partnerService: PartnerService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		try {
			let partnerCode: string = this.partnerService.getPartnerCode();
			if (partnerCode) {
				req = req.clone({ setHeaders: { partnerCode: partnerCode } });
			}

			return next.handle(req).pipe(
				catchError((err) => {
					console.error(err);
					if (err.status === 401) {
						err.error = "Session expired";
					} else if (err.status === 0) {
						err.error = "Unable connect to server.";
					}
					return throwError(err);
				}),
			);
		} catch (error) {
			return next.handle(req);
		}
	}
}
