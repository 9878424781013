import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-thankyou-purchase-dialog",
	templateUrl: "./thankyou-purchase-dialog.component.html",
	styleUrls: ["./thankyou-purchase-dialog.component.scss"],
})
export class ThankyouPurchaseDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: any,
	) {}
}
