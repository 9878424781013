<form [formGroup]="formGroup">
	<div style="background: #ddecfb; padding: 20px" fxLayout="column">
		<div class="mdTitle-f mv-sm">
			{{ "editQuotations.agentDetails" | translate }}
		</div>

		<mat-form-field appearance="outline" class="input-field">
			<mat-label>{{ "common.mobileNo" | translate }} </mat-label>
			<ngx-mat-intl-tel-input
				[enableSearch]="true"
				[preferredCountries]="['my']"
				formControlName="mobileNo"
			>
			</ngx-mat-intl-tel-input>
		</mat-form-field>
		<mat-form-field appearance="outline" class="input-field">
			<mat-label>{{ "common.email" | translate }} </mat-label>
			<input matInput formControlName="email" />
		</mat-form-field>
	</div>
	<div fxLayout="column" fxLayoutAlign="center" class="m-sm">
		<div class="mdTitle-f m-sm">
			{{ "editQuotations.requestType" | translate }}
		</div>

		<section>
			<mat-radio-group
				class="b2c-radio edit-quotation"
				aria-label="Select an option"
				matInput
				formControlName="requestType"
			>
				<mat-radio-button color="primary" value="all"
					>{{ "editQuotations.changeAll" | translate }}
				</mat-radio-button>
				<mat-radio-button color="primary" value="specific"
					>{{ "editQuotations.changeSpecific" | translate }}
				</mat-radio-button>
			</mat-radio-group>
		</section>
	</div>
</form>

<form [formGroup]="allFormGroup">
	<div
		class="edit-quotations"
		fxLayout="column"
		*ngIf="formGroup.get('requestType').value === 'all'"
	>
		<mat-form-field appearance="outline" class="input-field">
			<mat-label>{{ "common.sumInsured" | translate }} </mat-label>
			<input
				matInput
				formControlName="sumInsured"
				mask="separator.2"
				thousandSeparator=","
				autocomplete="off"
				separatorLimit="99999999999999"
			/>
		</mat-form-field>
		<div class="mdTitle-f">
			{{ "editQuotations.previousCarPlateNo" | translate }}
		</div>
		<div class="value-f pb-xs">
			{{ "editQuotations.previousCarPlateNoDesc" | translate }}
		</div>

		<mat-form-field appearance="outline" class="input-field upperc-input">
			<input
				matInput
				formControlName="previousVehicleNo"
				mask="A{255}"
				[validation]="false"
				autocomplete="off"
			/>
		</mat-form-field>
		<div class="mdTitle-f mb-sm">
			{{ "common.vehicleInformation" | translate }}
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.carModel" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.model }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="carModel" />
			</mat-form-field>
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.carMake" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.make }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="carMake" />
			</mat-form-field>
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.NVIC" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.nvic }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="nvic" />
			</mat-form-field>
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.variant" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.variant }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="variant" />
			</mat-form-field>
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.typeOfVehicle" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.typeOfVehicle }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<mat-select formControlName="typeOfVehicle">
					<mat-option value="Saloon">Saloon</mat-option>
					<mat-option value="Non-Saloon">Non-Saloon</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.transmission" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.transmission }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="transmission" />
			</mat-form-field>
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.manufacturingYear" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.year }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="year" mask="0000" autocomplete="off" />
			</mat-form-field>
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.cc" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.engineCC }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="cc" />
			</mat-form-field>
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.seats" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.seat }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="seats" />
			</mat-form-field>
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.engineNo" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.engineNo }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="engineNo" />
			</mat-form-field>
		</div>
		<div class="input-flex">
			<div class="label">
				{{ "common.chassisNo" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.chassisNo }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="chassisNo" />
			</mat-form-field>
		</div>
	</div>
</form>

<form [formGroup]="specificFormGroup">
	<div
		class="edit-quotations"
		fxLayout="column"
		*ngIf="formGroup.get('requestType').value === 'specific'"
	>
		<div class="mdTitle-f">Insurer</div>
		<div class="mdDesc-f mv-sm">
			{{ "editQuotations.selectInsurer" | translate }}
		</div>
		<mat-form-field appearance="outline" class="input-field mb-xs">
			<mat-select
				multiple
				formControlName="selectedMotorProductList"
				[placeholder]="'common.otherInsurersPH' | translate"
			>
				<mat-select-trigger>
					{{ getMotorProductDisplay() }}
				</mat-select-trigger>
				<mat-option>
					<ngx-mat-select-search
						[placeholderLabel]="'common.allInsurer' | translate"
						[ngModelOptions]="{ standalone: true }"
						[searching]="!motorProductList.length"
						[showToggleAllCheckbox]="true"
						[toggleAllCheckboxIndeterminate]="isIndeterminateMotorProduct()"
						[toggleAllCheckboxChecked]="isSelectedAllMotorProduct()"
						(toggleAll)="toggleSelectAllMotorProduct($event)"
						noEntriesFoundLabel="No matching insurer found"
						(ngModelChange)="filterMotorProductSelection($event)"
						ngModel
						ngDefaultControl
					>
					</ngx-mat-select-search>
				</mat-option>
				<mat-option
					*ngFor="let item of filterMotorProductList"
					[value]="item.id"
				>
					{{ item.name }}</mat-option
				>
			</mat-select>
		</mat-form-field>

		<mat-form-field appearance="outline" class="input-field">
			<mat-label>Sum insured </mat-label>
			<input
				matInput
				formControlName="sumInsured"
				mask="separator.2"
				thousandSeparator=","
				autocomplete="off"
				separatorLimit="99999999999999"
			/>
		</mat-form-field>

		<div class="input-flex">
			<div class="label">
				{{ "common.engineNo" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.engineNo }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="engineNo" />
			</mat-form-field>
		</div>

		<div class="input-flex">
			<div class="label">
				{{ "common.chassisNo" | translate }}
				<div class="smTitle-f color-muted">
					{{ vehicleData?.chassisNo }}
				</div>
			</div>
			<mat-form-field appearance="outline" class="input-field">
				<input matInput formControlName="chassisNo" />
			</mat-form-field>
		</div>
	</div>
</form>

<div class="mv-sm mh-md">
	<button
		class="full-btn"
		mat-button
		(click)="submit()"
		[disabled]="formGroup.invalid"
	>
		{{ "common.submit" | translate }}
	</button>
</div>
