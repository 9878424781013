import { Component, OnInit } from "@angular/core";
import { PartnerService } from "src/app/share/service/partner.service";

@Component({
	selector: "app-guides-dialog",
	templateUrl: "./guides-dialog.component.html",
	styleUrls: ["./guides-dialog.component.scss"],
})
export class GuidesDialogComponent implements OnInit {
	partnerCode: string;

	constructor(private partnerService: PartnerService) {}

	ngOnInit(): void {
		this.getPartnerCode();
	}

	getPartnerCode() {
		this.partnerCode = this.partnerService.getPartnerCode();
		!this.partnerCode ? (this.partnerCode = "carsomeCapital") : null;
	}
}
