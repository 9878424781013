import { Component, OnInit } from "@angular/core";
import { PartnerService } from "src/app/share/service/partner.service";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: [],
})
export class FooterComponent implements OnInit {
	partnerCode: string;

	psFooterList: string[] = ["mytukar"];
	isPSFooter: Boolean = false;

	customFooterList: string[] = ["carsomeCapital"];
	isCustomFooter: Boolean = false;

	pathName: string = window.location.pathname;

	constructor(private partnerService: PartnerService) {}

	ngOnInit(): void {
		this.getPartnerCode();
	}

	private getPartnerCode() {
		this.partnerCode = this.partnerService.getPartnerCode();
		!this.partnerCode ? (this.partnerCode = "carsomeCapital") : null;
		if (this.psFooterList.includes(this.partnerCode)) {
			this.isPSFooter = true;
		}
		if (this.customFooterList.includes(this.partnerCode)) {
			this.isCustomFooter = true;
		}
	}
}
