import { Injectable } from "@angular/core";
import {
	MatSnackBar,
	MatSnackBarRef,
	SimpleSnackBar,
} from "@angular/material/snack-bar";

@Injectable({
	providedIn: "root",
})
export class AlertService {
	constructor(private _snackBar: MatSnackBar) {}

	public openSnackBar(
		message: string,
		actionText: string = null,
		duration: number = 0,
	): MatSnackBarRef<SimpleSnackBar> {
		if (!actionText) actionText = "DISMISS";
		if (duration <= 0) duration = 3000;

		return this._snackBar.open(message, actionText, {
			duration: duration,
		});
	}
	public dismissVisibleSnackBar(): void {
		this._snackBar.dismiss();
	}
}
