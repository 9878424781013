<div style="background: #eef4fc">
	<div style="max-width: 950px; margin: 0 auto; padding: 20px">
		<div class="comparison-form">
			<form>
				<div fxLayout="row wrap" fxLayoutGap="16px grid">
					<div fxFlex.lt-md="100" fxFlex.gt-sm="60">
						<div class="section-container mb-lg">
							<div class="title-sec">
								<div class="title-f">
									{{ "common.orderSummary" | translate }}
									{{ "topup.revised" | translate }}
								</div>
								<div class="desc">
									{{ "common.orderSummaryDesc" | translate }}
								</div>
							</div>
						</div>
						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.carProtectionPlan" | translate }}
								</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div fxLayout="row" fxLayoutGap="16px grid">
								<div fxFlex="50">
									<mat-card>
										<img
											class="full-width"
											[src]="policyDetail?.productImage"
											alt=""
										/>
									</mat-card>
								</div>
								<div class="content-wrapper m-t-0" fxFlex="50">
									<div>
										<div class="label-desc">
											{{ "common.insuranceProvider" | translate }} :
										</div>
										<div class="label-value">
											{{ policyDetail?.productName }}
										</div>
									</div>
									<div>
										<div class="label-desc">
											{{ "common.typeOfInsurance" | translate }} :
										</div>
										<div class="label-value">
											{{ "common." + policyDetail?.insuranceType | translate }}
										</div>
									</div>
									<div>
										<div class="label-desc">
											{{ "common.sumInsured" | translate }} :
										</div>
										<div class="label-value">
											RM
											{{ policyDetail?.sumInsured | number : "1.2-2" }}
										</div>
									</div>
									<div>
										<div class="label-desc">
											{{ "common.effectivePeriod" | translate }} :
										</div>
										<div class="label-value">
											{{ policyDetail?.effectiveDate | date : "dd MMM yyyy" }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- ROADTAX SECTION -->
						<div class="mb-sm" *ngIf="policyDetail?.includeRoadTax">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">{{ "addOn.addOns" | translate }}</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="add-ons-container">
								<div class="add-ons-cover" *ngIf="policyDetail?.includeRoadTax">
									{{ "addOn.roadTax" | translate }}
								</div>
								<div class="add-ons-cover" *ngFor="let item of addOn">
									{{ "addOn." + item.code | translate }}
								</div>
							</div>
						</div>
						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.carDetails" | translate }} :
								</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="left label-desc">
									{{ "common.carNumber" | translate }} :
								</div>
								<div class="right label-value">
									{{ policyDetail?.vehicleNo }}
								</div>
								<div class="left label-desc">
									{{ "common.carModel" | translate }} :
								</div>
								<div class="right label-value">
									{{ policyDetail?.make }} {{ policyDetail?.model }}
									{{ policyDetail?.transmission }}
								</div>
							</div>
						</div>
						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.personalDetails" | translate }}
								</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="label-desc left">
									{{ getNameLabel() | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.name }}
								</div>

								<ng-container *ngTemplateOutlet="personalDetail">
								</ng-container>

								<div class="label-desc left">
									{{ "common.occupation" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.occupation }}
								</div>

								<div class="label-desc left">
									{{ "common.mobileNo" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.mobileNo }}
								</div>

								<div class="label-desc left">
									{{ "common.email" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.email }}
								</div>
							</div>
						</div>

						<div class="mb-sm">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.residentialAddress" | translate }}
								</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="label-desc left">
									{{ "common.addressLineOne" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.address }}
								</div>
								<div class="label-desc left">
									{{ "common.addressLineTwo" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.address2 }}
								</div>
								<div class="label-desc left">
									{{ "common.city" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.city }}
								</div>
								<div class="label-desc left">
									{{ "common.postcode" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.postcode }}
								</div>
								<div class="label-desc left">
									{{ "common.state" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.state }}
								</div>
							</div>
						</div>

						<!-- ROADTAX SECTION -->
						<div class="mb-sm" *ngIf="policyDetail?.includeRoadTax">
							<div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
								<div class="mdTitle-f">
									{{ "common.deliveryAddress" | translate }}
								</div>
								<div class="summary-divider grey" fxFlex></div>
							</div>
							<div class="content-wrapper">
								<div class="label-desc left">
									{{ "common.addressLineOne" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.deliveryAddress }}
								</div>
								<div class="label-desc left">
									{{ "common.addressLineTwo" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.deliveryAddress2 }}
								</div>
								<div class="label-desc left">
									{{ "common.city" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.deliveryCity }}
								</div>
								<div class="label-desc left">
									{{ "common.postcode" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.deliveryPostcode }}
								</div>
								<div class="label-desc left">
									{{ "common.state" | translate }} :
								</div>
								<div class="label-value right">
									{{ policyDetail?.deliveryState }}
								</div>
							</div>
						</div>

						<div class="mt-md">
							<button
								mat-button
								class="full-btn third pay-now-btn"
								(click)="handleSubmit()"
							>
								{{ "common.payNow" | translate }}
							</button>
						</div>
					</div>
					<div fxFlex.lt-md="100" fxFlex.gt-sm="40">
						<mat-expansion-panel
							class="summary-card"
							hideToggle
							disabled
							#summary="matExpansionPanel"
							[expanded]="screenWidth > 960"
						>
							<mat-expansion-panel-header
								[collapsedHeight]="'100%'"
								[expandedHeight]="'100%'"
							>
								<button
									mat-icon-button
									class="expand-btn"
									(click)="summary.expanded = !summary.expanded"
									*ngIf="!summary.expanded"
								>
									<mat-icon>keyboard_arrow_up</mat-icon>
								</button>
								<button
									mat-flat-button
									class="proceed-btn"
									(click)="handleSubmit()"
								>
									<ng-container *ngTemplateOutlet="mobileNextBtn">
									</ng-container>
								</button>
							</mat-expansion-panel-header>
							<button
								mat-icon-button
								class="expand-btn"
								(click)="summary.expanded = !summary.expanded"
								*ngIf="summary.expanded"
							>
								<mat-icon>keyboard_arrow_down</mat-icon>
							</button>
							<div class="expanded-content">
								<ng-container *ngTemplateOutlet="priceList"> </ng-container>
								<!-- <ng-container *ngTemplateOutlet="promoTemplate"> </ng-container> -->
								<!-- <div class="payment-options">
                  <div class="label-desc">Payment option :</div>
                  <mat-radio-group (change)="paymentTypeChange($event.value)">
                    <mat-radio-button
                      value="IPay88"
                      [checked]="selectedPayment === 'IPay88'"
                    >
                      <div class="desc">Full Price</div>
                      <div class="value">
                        RM {{ getUnpaidRemainingAmount() | number: "1.2-2" }}
                      </div>
                    </mat-radio-button>
                    <mat-radio-button
                      value="PayWithSplit"
                      [checked]="selectedPayment === 'PayWithSplit'"
                    >
                      <div class="desc">3 mth instalment</div>
                      <div class="value">
                        RM
                        {{ getUnpaidRemainingAmount() / 3 | number: "1.2-2" }}
                      </div>
                      <div class="sub-desc">per month</div>
                    </mat-radio-button>
                  </mat-radio-group>
                </div> -->
							</div>
						</mat-expansion-panel>

						<div
							class="expand-blackdrop"
							(click)="summary.expanded = !summary.expanded"
							*ngIf="summary.expanded"
						></div>
					</div>
				</div>
			</form>

			<ng-template matStepperIcon="edit">
				<mat-icon>edit</mat-icon>
			</ng-template>

			<ng-template matStepperIcon="close">
				<mat-icon>close</mat-icon>
			</ng-template>
		</div>

		<ng-template #priceList>
			<img [src]="policyDetail?.productImage" class="selected-insurer" alt="" />
			<mat-expansion-panel class="summary-expand">
				<mat-expansion-panel-header
					[collapsedHeight]="'100%'"
					[expandedHeight]="'100%'"
				>
					<mat-panel-title>
						{{ "common.benefits" | translate }}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="expand-content">
					<app-benefit [code]="selectedQuotation?.code"></app-benefit>
				</div>
			</mat-expansion-panel>

			<div *ngIf="topUpRequest?.type !== 'PPP'">
				<div class="content-wrapper">
					<div class="left">
						<div class="label-desc">
							{{ "common.sumInsured" | translate }} (SI)
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ policyDetail?.sumInsured | number : "1.2-2" }}
						</div>
					</div>
					<div class="left">
						<div class="label-desc">{{ "common.basePremium" | translate }}</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ policyDetail?.basePremium | number : "1.2-2" }}
						</div>
					</div>
					<div class="left">
						<div class="label-desc">
							{{ "common.noClaimDiscount" | translate }} <br />
							<b>({{ policyDetail?.ncd * 100 | number : "1.0-2" }}%)</b>
							<b *ngIf="!policyDetail?.ncd"> - </b>
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right color-red">
							- RM
							{{ getNCD() | number : "1.2-2" }}
						</div>
					</div>
				</div>
				<mat-divider></mat-divider>
				<div class="content-wrapper">
					<div class="left">
						<div class="label-desc">
							{{ "common.totalBasePremium" | translate }}
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ getBasePremium() | number : "1.2-2" }}
						</div>
					</div>

					<div *ngIf="hasAddOn()">
						<span class="cover-label">{{
							"addOn.addOnsCapital" | translate
						}}</span>
					</div>

					<!-- Add on in card here -->
					<ng-container *ngFor="let item of addOn">
						<ng-container *ngIf="!item.hidePriceBreakdown">
							<div class="left">
								<div class="label-desc">
									{{ "addOn." + item.code | translate }}
								</div>
							</div>
							<div class="right">
								<ng-container [ngSwitch]="item.code">
									<ng-container *ngSwitchCase="'Windshield'">
										<div class="label-value text-right">
											RM
											{{ item.amount | number : "1.2-2" }}
										</div>
									</ng-container>
									<ng-container *ngSwitchCase="'AdditionalDrivers'">
										<div class="label-value text-right">
											RM
											{{ item.amount | number : "1.2-2" }}
										</div>
									</ng-container>
									<ng-container *ngSwitchDefault>
										<div class="label-value text-right">
											RM
											{{ item.amount | number : "1.2-2" }}
										</div>
									</ng-container>
								</ng-container>
							</div>
						</ng-container>
					</ng-container>
				</div>
				<mat-divider></mat-divider>

				<mat-divider></mat-divider>

				<div class="content-wrapper">
					<div class="left">
						<div class="label-desc">
							{{ "common.totalGrossPremium" | translate }}
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM
							{{ getGrossPremium() | number : "1.2-2" }}
						</div>
					</div>
					<div class="left">
						<div class="label-desc">
							{{ "common.serviceTax" | translate }}
							({{ policyDetail?.taxRate * 100 | number : "1.0-2" }}%)
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM
							{{ getTax() | number : "1.2-2" }}
						</div>
					</div>

					<div class="left">
						<div class="label-desc">{{ "common.stampDuty" | translate }}</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ policyDetail?.stampDutyPrice | number : "1.2-2" }}
						</div>
					</div>
				</div>

				<mat-divider></mat-divider>

				<div class="content-wrapper">
					<div class="left">
						<div class="label-desc">
							{{ "common.totalNetPremium" | translate }}
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM
							{{ getTotalNetPremium() | number : "1.2-2" }}
						</div>
					</div>

					<ng-container *ngIf="policyDetail?.platformPrice">
						<div class="left">
							<div class="label-desc">
								{{ "common.platformOffer" | translate }}
							</div>
						</div>
						<div class="right">
							<div class="label-value text-right color-red">
								- RM
								{{ getPlatformDiscountRate() | number : "1.2-2" }}
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="policyDetail?.includeRoadTax">
						<div class="left">
							<div class="label-desc">
								{{ "addOn.roadTax" | translate }}
							</div>
						</div>
						<div class="right">
							<div class="label-value text-right">
								RM {{ policyDetail?.roadTaxPrice | number : "1.2-2" }}
							</div>
						</div>
						<div class="left">
							<div class="label-desc">
								{{ "addOn.roadTaxDelivery" | translate }}
							</div>
						</div>
						<div class="right">
							<div class="label-value text-right">
								RM {{ policyDetail?.roadTaxDeliveryPrice | number : "1.2-2" }}
							</div>
						</div>
					</ng-container>
				</div>

				<mat-divider></mat-divider>

				<div class="content-wrapper">
					<div class="left">
						<div class="label-desc">
							{{ "common.totalCharges" | translate }}
						</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ getTotalCharge() | number : "1.2-2" }}
						</div>
					</div>

					<!-- PROMO SECTION -->
					<ng-container *ngIf="policyDetail?.promoCodePrice">
						<div class="left">
							<div class="label-desc">Promo</div>
						</div>
						<div class="right">
							<div class="label-value text-right color-red">
								- RM {{ getTotalPromo() | number : "1.2-2" }}
							</div>
						</div>
					</ng-container>

					<div class="left">
						<div class="label-desc">{{ "common.serviceFee" | translate }}</div>
					</div>
					<div class="right">
						<div class="label-value text-right">
							RM {{ getServiceFee() | number : "1.2-2" }}
						</div>
					</div>
				</div>

				<mat-divider></mat-divider>
			</div>

			<div class="content-wrapper">
				<div class="left">
					<div class="label-desc">
						{{ "topup.totalRevised" | translate }}
						<span class="bold">
							({{
								"topup.totalRevisedNote" + topUpRequest?.type | translate
							}})</span
						>
						<!-- <span class="bold" *ngIf="requestType == 'NCD'">
              ({{ "topup.totalRevisedNote" + topUpRequest?.type | translate }}
              {{ requestAmount | number: "1.0-2" }}
              %)</span
            > -->
					</div>
				</div>
				<div class="right">
					<div class="label-value text-right">
						RM {{ getTotal() | number : "1.2-2" }}
					</div>
				</div>

				<div class="left">
					<div class="label-desc">{{ "topup.totalPaid" | translate }}</div>
				</div>
				<div class="right">
					<div class="label-value text-right color-dark-green">
						RM {{ getTotalPaid() | number : "1.2-2" }}
					</div>
				</div>
			</div>

			<mat-divider></mat-divider>

			<div class="content-wrapper remaining-amount-container">
				<div class="left">
					<div class="label-desc">
						{{ "topup.remainingAmount" | translate }}
					</div>
				</div>
				<div class="right">
					<div class="text-right">
						<p class="remaining-amount">
							RM {{ getUnpaidRemainingAmount() | number : "1.2-2" }}
						</p>
					</div>
				</div>
			</div>
		</ng-template>

		<ng-template #personalDetail>
			<div class="label-desc left">
				{{ getIdentityNoLabel() | translate }} :
			</div>
			<div class="label-value right">
				{{ policyDetail?.identityNo }}
			</div>

			<ng-container *ngIf="!policyDetail?.isCompany">
				<ng-container *ngIf="policyDetail?.isMalaysian">
					<ng-container *ngIf="policyDetail?.policIdentityNo">
						<div class="label-desc left">
							{{ "common.policeOrArmyIcNo" | translate }} :
						</div>
						<div class="label-value right">
							{{ policyDetail?.policIdentityNo || "-" }}
						</div>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="!policyDetail?.isMalaysian">
					<div class="label-desc left">
						{{ "common.nationality" | translate }} :
					</div>
					<div class="label-value right">
						{{ policyDetail?.nationality }}
					</div>
				</ng-container>

				<div class="label-desc left">{{ "common.gender" | translate }} :</div>
				<div class="label-value right">
					{{ "common." + policyDetail?.gender | translate }}
				</div>
				<div class="label-desc left">
					{{ "common.maritalStatus" | translate }} :
				</div>
				<div class="label-value right">
					{{ "common." + policyDetail?.maritalStatus | translate }}
				</div>

				<div class="label-desc left">{{ "common.dob" | translate }} :</div>
				<div class="label-value right">
					{{ policyDetail?.dob | date : "dd MMM yyyy" }}
				</div>
			</ng-container>
		</ng-template>

		<ng-template #mobileNextBtn>
			<div class="title">
				{{ "common.payNow" | translate }}
			</div>
			<div>
				<div class="value">
					RM {{ getUnpaidRemainingAmount() | number : "1.2-2" }}
				</div>
				<div class="desc">
					{{ "common.pay" | translate }} RM
					{{ getTotal() / 3 | number : "1.2-2" }}
					{{ "common.everyMonth" | translate }}
          			{{ "common.duration" | translate }}!
				</div>
			</div>
		</ng-template>
	</div>
</div>

<!-- My questions

1) Road tax price and road tax delivery price 0 even on RTX case
2) ServiceTaxRate not on the JSON but got taxRate, (same, different?)
3) Where is the total Paid amount in the JSON?
4) Where would this link to when you click proceed
5) add some add ons to the sample quotation for test

-->
