import {CommonModule} from "@angular/common";
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {TranslateModule} from "@ngx-translate/core";
import {NgxMaskModule} from "ngx-mask";
import {RangeSliderComponent} from './range-slider.component';

@NgModule({
  exports: [RangeSliderComponent],
  declarations: [RangeSliderComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgxMaskModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class RangeSliderModule {
}
