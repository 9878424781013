<div>
	<div class="renewal-graphic">
		<img src="assets/image/renewal-graphic.png" alt="" />
	</div>
	<div class="renewal-content">
		<div>
			{{ "renewal.sendEmailReminder" | translate }} email@gmail.com
			{{ "renewal.closerDate" | translate }}
		</div>
	</div>

	<div fxLayout="row" fxLayoutGap="16px grid">
		<div fxFlex>
			<button mat-flat-button class="secondary-btn" mat-dialog-close>
				{{ "renewal.close" | translate }}
			</button>
		</div>
	</div>
</div>
