<div mat-dialog-title>FAQ</div>
<mat-divider></mat-divider>
<mat-dialog-content>
	<ol class="faq-ol">
		<li>
			<div class="faq-title">
				{{ "faq.whoIsPSTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.whoIsPSDesc" | translate }}
				<a
					href="https://www.bnm.gov.my/index.php?ch=li_fin_adv&pg=li_fin_adv&ac=3#gsc.tab=0"
					target="blank"
					>{{ "faq.whoIsPSLink" | translate }}</a
				>
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.renewInsAndRoadtaxTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.renewInsAndRoadtaxDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.instantQuoteInsurersTitle" | translate }}
			</div>
			<div class="faq-desc">
				<ol class="child-ol">
					<li>AXA Affin General Insurance Berhad</li>
					<li>Etiqa General Takaful Berhad</li>
					<li>Berjaya Sompo Insurance Berhad</li>
					<li>Syarikat Takaful Malaysia Am Berhad</li>
					<li>RHB Insurance Berhad</li>
					<li>MSIG Insurance Berhad</li>
					<li>Tune Insurance Malaysia Berhad</li>
					<li>Zurich General Insurance Malaysia Berhad</li>
					<li>Pacific & Orient Insurance Co. Bhd</li>
					<li>Allianz General Insurance Company (Malaysia) Berhad</li>
				</ol>
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.otherInsurersTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.otherInsurersDesc1" | translate }}
				<ol class="child-ol" style="margin-left: 10px">
					<li>AmGeneral Insurance Berhad</li>
					<li>Kurnia Insurans (Malaysia) Berhad</li>
					<li>Lonpac Insurace Berhad</li>
					<li>Tokio Marine Insurans Berhad</li>
					<li>Chubb Insurance Malaysia Berhad</li>
					<li>Great Eastern General Insurance (Malaysia)</li>
					<li>MPI Generali Insurans Berhad</li>
					<li>QBE Insurance (Malaysia) Berhad</li>
					<li>Liberty Insurance Malaysia Berhad</li>
					<li>Zurich General Takaful Malaysia Berhad</li>
				</ol>
				{{ "faq.otherInsurersDesc2" | translate }}

				<ol
					style="list-style-type: lower-alpha; margin-left: 10px"
					class="child-ol"
				>
					<li>{{ "faq.callDesc" | translate }}</li>
					<li>{{ "faq.whatsappDesc" | translate }}</li>
					<li>{{ "faq.emailDesc" | translate }}</li>
				</ol>
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.tncRedeemPromoTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.tncRedeemPromoDesc1" | translate }}
				<ul class="child-ul">
					<li>{{ "faq.tncRedeemPromoDesc2" | translate }}</li>
					<li>{{ "faq.tncRedeemPromoDesc3" | translate }}</li>
					<li>{{ "faq.tncRedeemPromoDesc4" | translate }}</li>
					<li>{{ "faq.tncRedeemPromoDesc5" | translate }}</li>
				</ul>
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.forgotExpiryDateTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.forgotExpiryDateDesc" | translate }}
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.whoCanUseServiceTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.whoCanUseServiceDesc" | translate }}
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.benefitsOfRenewTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.benefitsOfRenewDesc1" | translate }}
				<br /><br />
				{{ "faq.benefitsOfRenewDesc2" | translate }}
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.renewOnlineSafetyTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.renewOnlineSafetyDesc" | translate }}
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.vehiclesToRenewTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.vehiclesToRenewDesc1" | translate }}
				<br /><br />
				{{ "faq.vehiclesToRenewDesc2" | translate }}
				<span class="color-blue">policystreet.com/motorcycle</span>
				{{ "faq.vehiclesToRenewDesc3" | translate }}
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.transactionSucessTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.transactionSucessDesc1" | translate }}
				<br /><br />
				{{ "faq.callDesc" | translate }}
				<br /><br />
				{{ "faq.whatsappDesc" | translate }}
				<br /><br />
				{{ "faq.emailDesc" | translate }}
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.paymentOptionsTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.paymentOptionsDesc1" | translate }}
				<ul style="list-style-type: circle; margin-left: 10px" class="child-ul">
					<li>{{ "faq.paymentOptionsDesc2" | translate }}</li>
					<li>{{ "faq.paymentOptionsDesc3" | translate }}</li>
					<li>{{ "faq.paymentOptionsDesc4" | translate }}</li>
				</ul>
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.renewIfOutstandingSummonTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.renewIfOutstandingSummonDesc" | translate }}
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.receivePolicyTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.receivePolicyDesc" | translate }}
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.receiveRoadtaxTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.receiveRoadtaxDesc1" | translate }}
				<ul style="list-style-type: circle; margin-left: 10px" class="child-ul">
					<li>{{ "faq.receiveRoadtaxDesc2" | translate }}</li>
					<li>{{ "faq.receiveRoadtaxDesc3" | translate }}</li>
					<li>{{ "faq.receiveRoadtaxDesc4" | translate }}</li>
				</ul>
				{{ "faq.receiveRoadtaxDesc5" | translate }}}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.chargesOfRenewalTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.chargesOfRenewalDesc" | translate }}
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.viewPaymentTransactionTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.viewPaymentTransactionDesc" | translate }}
			</div>
		</li>

		<li>
			<div class="faq-title">
				{{ "faq.receiptUponPaymentTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.receiptUponPaymentDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.printRegCardTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.printRegCardDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.paymentRejectedTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.paymentRejectedDesc1" | translate }}
				<br /><br />
				{{ "faq.paymentRejectedDesc2" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.cancelAndRefundTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.cancelAndRefundDesc1" | translate }}
				<br /><br />
				{{ "faq.cancelAndRefundDesc2" | translate }}
				<br /><br />
				{{ "faq.callDesc" | translate }}
				<br /><br />
				{{ "faq.whatsappDesc" | translate }}
				<br /><br />
				{{ "faq.emailDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.contactForAssistTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.contactForAssistDesc1" | translate }}
				<br /><br />
				{{ "faq.callDesc" | translate }}
				<br /><br />
				{{ "faq.whatsappDesc" | translate }}
				<br /><br />
				{{ "faq.emailDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.serviceAvailableTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.serviceAvailableDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.makeClaimTitle" | translate }}
			</div>
			<div class="faq-desc">
				<ol class="child-ol">
					<li>AXA Affin General Insurance Berhad - 03-2170-8282</li>
					<li>Etiqa General Takaful Berhad - 1-300-88-1007 / 1-800-88-6491</li>
					<li>
						Berjaya Sompo Insurance Berhad - 03-7628-3822 / 03-7841-5660 /
						1-800-889-933
					</li>
					<li>Syarikat Takaful Malaysia Am Berhad - 1-800-888-788</li>
					<li>RHB Insurance Berhad - 1-300-880-881</li>
					<li>Takaful Ikhlas General Berhad –1-800-88-1186</li>
					<li>MSIG Insurance Berhad – 1-800-880-883</li>
					<li>Tune Insurance Malaysia Berhad – 1800-22-8863</li>
					<li>Zurich General Insurance Malaysia Berhad – 1-300-88-1323</li>
					<li>Pacific &amp; Orient Insurance Co. Bhd – 1-800-88-2121</li>
					<li>
						Allianz General Insurance Company (Malaysia) Berhad – 1-800-22-5542
					</li>
				</ol>
				{{ "faq.makeClaimDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.vehicleIsStolenTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.vehicleIsStolenDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.replacementCarTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.replacementCarDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.whatIsExcessTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.whatIsExcessDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.whatIsNCDTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.whatIsNCDDesc" | translate }}
			</div>
		</li>
		<li>
			<div class="faq-title">
				{{ "faq.purchasePromilejExpectTitle" | translate }}
			</div>
			<div class="faq-desc">
				{{ "faq.purchasePromilejExpectDesc" | translate }}
			</div>
		</li>
	</ol>
</mat-dialog-content>
