import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "src/app/share/service/alert.service";
import {
	ILoadingWords,
	LOADING_KEYWORDS_LIST,
	LOADING_WORDINGS_LIST,
} from "./loading-wordings-list";
import { Router } from "@angular/router";
import { TransferOwnershipDataService } from "src/app/share/data-service/transfer-ownership.data.service";
import { BeforeExpiredDialogComponent } from "../before-expired-dialog/before-expired-dialog.component";

@Component({
	selector: "app-loading-quotation",
	templateUrl: "./loading-quotation.component.html",
	styleUrls: ["./loading-quotation.component.scss"],
})
export class LoadingQuotationComponent implements OnInit {
	countKeyword: number = 1;
	countWord: number = 1;
	countTimeLeft: number = 14;
	keyword: string = "BEST";
	keywordList: string[] = LOADING_KEYWORDS_LIST;

	wordingList: ILoadingWords[] = LOADING_WORDINGS_LIST;
	firstWording: string = this.wordingList[0].first;
	secondWording: string = this.wordingList[0].second;

	timeLeftState: number = 15;
	stopTimerState: boolean = false;
	finishedState: boolean = false;
	beforeExpiredState: boolean = false;

	@Input() formData: FormData;

	constructor(
		private transferOwnershipDataService: TransferOwnershipDataService,
		private dialog: MatDialog,
		private alertService: AlertService,
		private router: Router,
	) {}

	loopWordingsList() {
		let index = this.countWord % this.wordingList.length;

		this.firstWording = this.wordingList[index]?.first;
		this.secondWording = this.wordingList[index]?.second;

		if (index == this.wordingList.length - 1) {
			setTimeout(() => {
				this.stopTimerState = true;
				// Don't show high demands page if its a before expired case
				if (!this.beforeExpiredState) {
					this.finishedState = true;
				}
			}, 15000);
		} else {
			this.countWord++;
		}
	}

	loopKeywordsList() {
		let index = this.countKeyword % this.keywordList.length;

		this.keyword = this.keywordList[index];

		if (!this.stopTimerState) {
			this.countKeyword++;
		}
	}

	loopNumberCountdown() {
		var timeLeft = 15;
		let index = this.countTimeLeft % timeLeft;

		this.timeLeftState = index;

		if (this.countTimeLeft !== 0 && !this.stopTimerState) {
			this.countTimeLeft--;
		} else {
			this.countTimeLeft = 14;
		}
	}

	ngOnInit(): void {
		this.runTimerLogic();
		this.submitQuotation();
	}

	runTimerLogic() {
		let keywordInterval = setInterval(() => this.loopKeywordsList(), 3000);
		let wordingInterval = setInterval(() => {
			this.loopWordingsList();
		}, 15000);
		let loopNumberCountdown = setInterval(
			() => this.loopNumberCountdown(),
			1000,
		);
		if (this.stopTimerState) {
			clearInterval(wordingInterval);
			clearInterval(keywordInterval);
			clearInterval(loopNumberCountdown);
		}
	}

	backToHome() {
		window.location.href = "/";
	}

	submitQuotation() {
		if (this.formData) {
			this.transferOwnershipDataService.submit(this.formData).subscribe({
				next: (x: any) => {
					this.stopTimerState = true;
					if (x.isSuccess && x.redirect_url) {
						window.location.href = x.redirect_url;
						return;
					}
					if (x.isSuccess && !x.redirect_url) {
						this.finishedState = true;
					} else {
						this.finishedState = false;
						this.beforeExpiredState = true;
						const dialogRef = this.dialog.open(BeforeExpiredDialogComponent, {
							panelClass: "",
							data: x.data,
						});
						dialogRef.afterClosed().subscribe((x) => {
							this.backToHome();
						});
					}
				},
				error: (err) => {
					this.stopTimerState = true;
					this.finishedState = true;
					this.alertService.openSnackBar(
						"Please contact PolicyStreet support.",
					);
				},
			});
		}
	}
}
