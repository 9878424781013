import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class TransferOwnershipDataService {
	private basePath: string = `${environment.url}/api/TransferOwnership/`;

	constructor(private http: HttpClient) {}

	submit(values) {
		return this.http.post<any>(`${this.basePath}Submit`, values);
	}

	getAllProduct(id: string) {
		return this.http.get<any>(
			`${this.basePath}GetAllProduct/${id}`,
		);
	}

	editExistingQuotes(id: string, values: any) {
		return this.http.post<any>(
			`${this.basePath}EditExistingQuotes/${id}`,
			values,
		);
	}

	editSpecificQuotes(id: string, values: any) {
		return this.http.post<any>(
			`${this.basePath}EditSpecificQuotes/${id}`,
			values,
		);
	}

	getHirePurchaseList() {
		return this.http.get<any>(`${this.basePath}GetHirePurchaseList`);
	}

	submitOrder(id: string, values: any) {
		return this.http.post<any>(`${this.basePath}SubmitOrder/${id}`, values);
	}
}
