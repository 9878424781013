import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MotorRequestInterface } from "src/app/share/interface/motor.interface";

@Component({
	selector: "app-car-plan-dialog",
	templateUrl: "./car-plan-dialog.component.html",
	styleUrls: ["./car-plan-dialog.component.scss"],
})
export class CarPlanDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: MotorRequestInterface) {}
}
