<div class="mdTitle-f">
	<img class="title-img" src="assets/image/car-icon.png" alt="" />
	{{ "common.carDetails" | translate }}
</div>
<div class="content-wrapper">
	<div>
		<div class="label-desc">{{ "common.carNumber" | translate }}:</div>
		<div class="label-value">
			{{ data.vehicleNo }}
		</div>
	</div>
	<div>
		<div class="label-desc">{{ "common.carModel" | translate }}:</div>
		<div class="label-value">{{ data.make }} {{ data.model }}</div>
	</div>
	<div>
		<div class="label-desc">Manufacturing year:</div>
		<div class="label-value">
			{{ data.year }}
		</div>
	</div>
	<div>
		<div class="label-desc">CC:</div>
		<div class="label-value">
			{{ data.engineCC }}
		</div>
	</div>
	<div>
		<div class="label-desc">Seats:</div>
		<div class="label-value">
			{{ data.seat }}
		</div>
	</div>
	<div>
		<div class="label-desc">Engine number:</div>
		<div class="label-value">
			{{ data.engineNo }}
		</div>
	</div>
	<div>
		<div class="label-desc">Chassis number:</div>
		<div class="label-value">
			{{ data.chassisNo }}
		</div>
	</div>
</div>
