<div class="value" [ngSwitch]="code">
	<div *ngSwitchCase="'MSIG'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.MSIG.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.MSIG.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.MSIG.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.MSIG.claimAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.MSIG.ehailing" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.emergencyTowing' | translate }} RM 200
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistanceProgram' | translate
        }}
      </div>
    </div>

    <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDriver' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.claimAssistance' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.ehailing' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.optionalCoverage' | translate }}{{ 'benefits.protectCost' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.optionalCoverage' | translate }}{{ 'benefits.waiveCost' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Pando'">
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.pando.towingService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.pando.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.pando.changeBatteryService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.pando.refuelEmptyTank" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.pando.tireReplacement" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.pando.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.pando.claimAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.pando.est" | translate }}
				<!-- <div class="desc">
          {{ 'benefits.goodClaimsRecordDesc' | translate }}
        </div> -->
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.pando.votedBest" | translate }}
				<!-- <div class="desc">
          {{ 'benefits.carsCoverageAgeDesc' | translate }}
        </div> -->
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.pando.insuranceAcceptance" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.emergencyTowing' | translate }} RM 350
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistanceTotalAssist' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.changeBatteryService' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.refuelEmptyTank' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.replaceFlatTyre' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDriver' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.claimAssistance' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.goodClaimsRecord' | translate }}
        <div class="desc">
          {{ 'benefits.goodClaimsRecordDesc' | translate }}
        </div>
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.carsCoverageAge' | translate }}
        <div class="desc">
          {{ 'benefits.carsCoverageAgeDesc' | translate }}
        </div>
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.establishedBrand' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.bestValuePrice' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.insuranceOldVehicles' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'AXA'">
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.AXA.warranty" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.AXA.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.AXA.rideupTo30KM" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.AXA.additionalBenefits" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefit.AXA.safeDrivingDiscount' | translate }}
        <div class="desc">
          {{ 'benefit.AXA.safeDrivingDiscountDesc' | translate }}
        </div>
      </div>
    </div> -->

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.AXA.instantApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.AXA.fastClaimApproval" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.emergencyTowing' | translate }} 50km
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistance' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.warranty' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.additionalBenefits' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.safeDrivingDiscount' | translate }}
        <div class="desc">
          {{ 'benefits.safeDrivingDiscountDesc' | translate }}
        </div>
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.instantApproval' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.fastClaimApproval' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.optionalCoverage' | translate }}{{ 'benefits.ehailingAddOn' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.limitedVehiclesAge' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Sompo'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.sompo.allDriversCoverage" | translate }}
				<!-- <div class="desc">
          {{ 'benefits.allDriversCoverageDesc' | translate }}
        </div> -->
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.sompo.roadsideAssistanceRakanAuto" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.sompo.nilExcess" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.sompo.specialPerlisCoverage" | translate }}
				<!-- <div class="desc">
          {{ 'benefits.specialPerlisCoverageDesc' | translate }}
        </div> -->
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.sompo.ehailing" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistanceRakanAuto' |
        translate }}
      </div>
    </div>

    <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.allDriversCoverage' | translate }}
        <div class="desc">
          {{ 'benefits.allDriversCoverageDesc' | translate }}
        </div>
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.nilExcess' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.specialPerlisCoverage' | translate
        }}
        <div class="desc">
          {{ 'benefits.specialPerlisCoverageDesc' | translate }}
        </div>
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.ehailing' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Takaful_Malaysia'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulMalaysia.allDriversCoverage" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulMalaysia.towingService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulMalaysia.roadsideAssistanceProgram" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulMalaysia.changeBatteryService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulMalaysia.refuelTank" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulMalaysia.tireReplacement" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.personalAccidentInPolicy' |
        translate }}
      </div>
    </div> -->

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.TakafulMalaysia.takafulOperator" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.TakafulMalaysia.cashBack" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.TakafulMalaysia.instantApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.TakafulMalaysia.fastClaimApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.TakafulMalaysia.nationwidePanelWorkshops" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.TakafulMalaysia.suitableNonMuslims" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.emergencyTowingEveryTrip' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistanceProgram' | translate
        }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.changeBatteryService' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.refuelEmptyTank' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.replaceFlatTyre' | translate }}
      </div>
    </div>

    <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.allDriversCoverage' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.personalAccidentInPolicy' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.firstTakafulOperator' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.instantApproval' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.fastClaimApproval' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.nationwidePanelWorkshops' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.potentialCashback' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.suitableNonMuslims' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Liberty'">
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.changeBatteryService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.refuelEmptyTank" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.replaceFlatTyre" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.claimAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.paCoverage" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.medicalExpenses" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.keyReplacement" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Liberty.childseatReplacement" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Liberty.ehailing" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.emergencyTowing' | translate }} 150km
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistance' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.changeBatteryService' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.refuelEmptyTank' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.replaceFlatTyre' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDriver' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.keyReplacement' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.childseatReplacement' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.claimAssistance' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.personalAccidentCarOwner' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.medicalExpensesCarOwner' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.ehailing' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Ikhlas'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulIkhlas.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{
				"benefit.TakafulIkhlas.roadsideAssistanceBantuanIkhlas" | translate
				}}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulIkhlas.changeBatteryService" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulIkhlas.changingFlatTyre" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulIkhlas.petrolAssist" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulIkhlas.lockSmitch" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.TakafulIkhlas.personalAccInsurance" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDrivers' | translate }}
      </div>
    </div> -->

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.locksmithServices' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.personalAccidentCarOwner' |
        translate }}
      </div>
    </div> -->
		<!-- <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.emergencyTowing' | translate }} 100km
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistanceBantuanIkhlas' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.changeBatteryService' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.refuelEmptyTank' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.replaceFlatTyre' | translate }}
      </div>
    </div> -->

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDrivers' | translate }}
      </div>
    </div> -->

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.locksmithServices' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.personalAccidentCarOwner' |
        translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Allianz'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Allianz.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Allianz.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Allianz.claimAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Allianz.bestCarInsurer" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Allianz.instantApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Allianz.fireInsurer" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Allianz.theftInsurer" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.emergencyTowing' | translate }} 150km
      </div>
    </div>

    <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDriver' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.claimAssistance' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.bestCarInsurer' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.ehailing' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.instantApproval' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Etiqa'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Etiqa.allDriversCoverage" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Etiqa.roadsideAssistance" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Etiqa.bestMotorTakaful" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistance' | translate }}
      </div>
    </div>

    <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.allDriversCoverage' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.bestMotorTakaful' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Tune'">
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Tune_Protect.fireInsurer" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Tune_Protect.theftInsurer" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.guranteeWorkmanship' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDriver' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.vaccinationSideEffectCoverage' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.refundDriveInitiative' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.limitedVehiclesCapacityAge' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-dark-blue">send</mat-icon>
      </div>
      <div>
        {{ 'benefits.amountImposedEachClaim' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Zurich'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Zurich.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Zurich.claimAssistance" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.fullFloodPerilCoverage' |
        translate }}
      </div>
    </div> -->

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Zurich.fastClaimApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Zurich.instantApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Zurich.ehailing" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.emergencyTowing' | translate }} RM 200
        {{ 'benefits.perYear' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.claimAssistance' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.fullFloodPerilCoverage' |
        translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.ehailing' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.instantApproval' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.fastClaimApproval' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'RHB'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.RHB.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.RHB.roadsideAssistance" | translate }}
			</div>
		</div>

		<!-- <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.emergencyTowing' | translate }} RM 300
        {{ 'benefits.perBreakdown' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistanceProgram' | translate
        }}
      </div>
    </div>

    <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.additionalDriver' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.ehailing' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.mobileAppClaims' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Kurnia'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Kurnia.autoAssistKurnia" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Kurnia.emergencyTowing" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Kurnia.additionalDriver" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Kurnia.fireInsurer" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Kurnia.theftInsurer" | translate }}
			</div>
		</div>
		<!-- <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b>{{ 'benefits.emergencyTowing' | translate }} RM 200
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.roadsideAssistanceProgram' | translate}} {{ 'benefits.viaKurnia' | translate }}
      </div>
    </div>

    <div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
      <div>
        <mat-icon class="color-green">check_circle</mat-icon>
      </div>
      <div>
        <b class="color-green">{{ 'benefits.free' | translate }}</b> {{ 'benefits.allDriversCoverage' | translate }}
        <div class="desc">
          {{ 'benefits.allDriversCoverageDesc' | translate }}
        </div>
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.fastClaimApproval' | translate }}
      </div>
    </div>

    <div class="expand-content">
      <div>
        <mat-icon class="color-yellow">star</mat-icon>
      </div>
      <div>
        {{ 'benefits.nationwidePanelWorkshops' | translate }}
      </div>
    </div> -->
	</div>

	<div *ngSwitchCase="'Pacific'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Pacific.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.Pacific.additionalDriver" | translate }}
			</div>
		</div>



		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Pacific.fireInsurer" | translate }}
			</div>
		</div>
		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Pacific.theftInsurer" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.Pacific.fastClaimApproval" | translate }}
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'GrabAXA'">
		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.AXAGrab.warranty" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.AXAGrab.roadsideAssistance" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.AXAGrab.rideupTo30KM" | translate }}
			</div>
		</div>

		<div class="expand-content" *ngIf="insuranceType !== 'TPFT'">
			<div>
				<mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
				<b class="color-green">{{ "benefits.free" | translate }}</b>
				{{ "benefit.AXAGrab.axaPreferredWorkshop" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.AXAGrab.safeDrivingDiscount" | translate }}
				<div class="desc">
					{{ "benefit.AXAGrab.safeDrivingDiscountDesc" | translate }}
				</div>
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.AXAGrab.instantApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.AXAGrab.fastClaimApproval" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.AXAGrab.personalAccidentCarOwner" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.AXAGrab.medicalClaims" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.AXAGrab.lossOfIncome" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.AXAGrab.loanProtection" | translate }}
			</div>
		</div>

		<div class="expand-content">
			<div>
				<mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
				{{ "benefit.AXAGrab.wheelAlignment" | translate }}
			</div>
		</div>
	</div>

	<div *ngSwitchCase="'Chubb'">
		

		<div class="expand-content">
			<div>
			  <mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
			  {{ "benefit.Chubb.coverForLoss" | translate }}
			</div>
		  </div>
		
		  <div class="expand-content">
			<div>
			  <mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
			  {{ "benefit.Chubb.emergencyTowing" | translate }}
			</div>
		  </div>
		
		  <div class="expand-content">
			<div>
			  <mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
			  {{ "benefit.Chubb.replacementCarService" | translate }}
			</div>
		  </div>
		
		  <div class="expand-content">
			<div>
			  <mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
			  {{ "benefit.Chubb.hotelReimbursement" | translate }}
			</div>
		  </div>
		
		  <div class="expand-content">
			<div>
			  <mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
			  {{ "benefit.Chubb.cashRelief" | translate }}
			</div>
		  </div>
		
		  <div class="expand-content">
			<div>
			  <mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
			  {{ "benefit.Chubb.liabilityToThirdParty" | translate }}
			</div>
		  </div>
		
		  <div class="expand-content">
			<div>
			  <mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
			  {{ "benefit.Chubb.minorRepairService" | translate }}
			</div>
		  </div>
		
		  <div class="expand-content">
			<div>
			  <mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
			  {{ "benefit.Chubb.additionalDriver" | translate }}
			</div>
		  </div>
		
		  <div class="expand-content">
			<div>
			  {{ "benefit.Chubb.convenience" | translate }}
			</div>
		  </div>
		
		  <div class="expand-content">
			<div>
			  <mat-icon class="color-green">check_circle</mat-icon>
			</div>
			<div>
			  {{ "benefit.Chubb.digitalClaimPortal" | translate }}
			</div>
		  </div>
		
		  <div class="expand-content">
			<div>
			  <mat-icon class="color-yellow">star</mat-icon>
			</div>
			<div>
			  {{ "benefit.Chubb.nationwidePanelWorkshop" | translate }}
			</div>
		  </div>
	</div>
</div>