import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Component, HostListener, ViewChild } from "@angular/core";

import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { NgxSpinnerService } from "ngx-spinner";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import {
	ProductAddOnInterface,
	QuotationInterface,
	ProductDocumentInterface,
} from "src/app/share/interface/motor.interface";
import { AlertService } from "src/app/share/service/alert.service";

@Component({
	selector: "app-topup-request",
	templateUrl: "./topup-request.component.html",
	styleUrls: ["./topup-request.component.scss"],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false },
		},
	],
})
export class TopupRequestComponent {
	screenWidth: number;

	id: string;

	selectedQuotation: QuotationInterface = {
		id: "d4de0997-9b83-4bde-aa45-767997d510ac",
		sumInsured: 200000,
		basePremium: 1234,
		name: "Etiqa",
		code: "Etiqa",
		image:
			"https://assets.policystreet.com/vendors/logos/product/etiqatakaful.png",
		totalBasePremium: 678.7,
		platformDiscountRate: 0.028,
		taxRate: 0.06,
		serviceTaxRate: 0.025,
		stampDutyPrice: 10,
		productId: "b1dd832a-7269-4e5a-920b-f0155ccf616b",
		total: 728.17886,
    maxWindshieldPrice: 0,
    minWindshieldPrice: 0,
	};

	policyDetail: any;
	topUpRequest: any;
	addOn: any;

	newNcd: number;
	newRoadTax: number;
	newPPP: number;
	requestType: string;
	requestAmount: number;

	@ViewChild("stepper") private stepper: MatStepper;

	stateList: string[] = [
		"Selangor",
		"Kuala Lumpur",
		"Johor",
		"Kedah",
		"Kelantan",
		"Melaka",
		"Negeri Sembilan",
		"Pahang",
		"Perak",
		"Perlis",
		"Pulau Pinang",
		"Sarawak",
		"Sabah",
		"Terengganu",
	];

	filterStateList: string[] = Object.assign([], this.stateList);
	filterDeliveryStateList: string[] = Object.assign([], this.stateList);

	productDocumentInterface: ProductDocumentInterface[] = [];

	selectedPayment: string = "IPay88";
	allowEditQuotation: boolean = false;

	isSameAddressCheck: boolean;

	constructor(
		public translate: TranslateService,
		private router: Router,
		private alertService: AlertService,
		private activatedRoute: ActivatedRoute,
		private ngxSpinnerService: NgxSpinnerService,
		private motorDataDataService: MotorDataDataService,
	) {
		this.screenWidth = window.innerWidth;
		this.id = activatedRoute.snapshot.params.id;

		this.motorDataDataService.getPolicyTopUpDetail(this.id).subscribe({
			next: (x: any) => {
				this.policyDetail = x.policyDetail;
				this.topUpRequest = x.topUpRequest;
				this.addOn = x.addOn;
				this.requestType = this.topUpRequest.type;
				this.requestAmount = this.topUpRequest.amount;
			},
			error: () => {
				window.location.href = "/";
			},
		});
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.screenWidth = event.target.innerWidth;
	}

	private isUUID(uuid: string) {
		return uuid.match(
			"^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$",
		);
	}

	paymentTypeChange(paymentType: string) {
		this.selectedPayment = paymentType;
	}

	handleSubmit() {
		this.router.navigate([], {
			queryParams: {
				id: this.id,
				pay: true,
			},
		});
		this.ngxSpinnerService.show();

		var value = {
			paymentProvider: this.selectedPayment,
		};

		this.motorDataDataService.generateTopupPayment(this.id).subscribe({
			next: (x) => {
				let navigationExtras: NavigationExtras = {
					queryParams: {
						id: x.id,
					},
				};
				this.ngxSpinnerService.hide();
				this.router.navigate(["/payment"], navigationExtras);
			},
			error: (err) => {
				this.alertService.openSnackBar(err.error);
				this.ngxSpinnerService.hide();
			},
		});
	}

	getTotalPromo() {
		// if (!this.promo) return 0;

		// let evaluate = this.promo.evaluate;
		// evaluate = evaluate.replace('{TotalGrossPremium}', this.getGrossPremium().toString());
		// return Number.parseFloat(eval(evaluate));

		return this.policyDetail?.promoCodePrice;
	}

	getIdentityNoLabel() {
		if (this.policyDetail?.isMalaysian && !this.policyDetail?.isCompany)
			return "common.icNo";
		else if (!this.policyDetail?.isMalaysian) return "common.passport";
		return "common.businessRegistrationNo";
	}

	getTax() {
		if (!this.policyDetail) return 0;
		let total = this.getGrossPremium();

		return total * this.policyDetail?.taxRate;
	}

	// get total base premium
	getBasePremium() {
		// -- Change to this if ncd using % -- //
		// if (this.requestType != 'NCD') {
		//   return this.policyDetail?.totalBasePremium;
		// } else {
		//   return this.policyDetail?.basePremium - this.getNCD();
		// }

		return this.policyDetail?.totalBasePremium;
	}

	getTotalNetPremium() {
		return (
			this.getGrossPremium() + this.getTax() + this.policyDetail?.stampDutyPrice
		);
	}

	getPlatformDiscountRate() {
		return this.policyDetail?.platformPrice
			? this.policyDetail?.platformPrice
			: 0;
	}

	getTotalCharge() {
		let total = this.getTotalNetPremium() - this.getPlatformDiscountRate();
		if (this.policyDetail?.includeRoadTax) {
			total =
				total +
				this.policyDetail?.roadTaxPrice +
				this.policyDetail?.roadTaxDeliveryPrice;
		}

		return total;
	}

	getServiceFee() {
		if (!this.policyDetail) return 0;

		// note service tax rate changed to tax rate
		return this.policyDetail?.servicePrice;
	}

	getTotal() {
		return (
			this.getTotalCharge() +
			this.getServiceFee() -
			this.getTotalPromo() +
			this.requestAmount
		);
	}

	getTotalPaid() {
		// -- Change to this if ncd using % -- //
		// if (this.requestType == 'NCD') {
		//   return this.policyDetail?.total;
		// } else {
		//   return this.getTotal() - this.topUpRequest?.amount;
		// }
		return this.getTotal() - this.topUpRequest?.amount;
	}

	getTotalAddOnPrice() {
		let total = 0;

		this.addOn.forEach((x: any) => {
			total += x.amount;
		});
		return total;
	}

	getGrossPremium() {
		if (!this.policyDetail) return 0;

		return this.getBasePremium() + this.getTotalAddOnPrice();
	}

	getNameLabel() {
		if (this.policyDetail?.isMalaysian && !this.policyDetail?.isCompany)
			return "common.namePerIc";
		else if (!this.policyDetail?.isMalaysian) return "common.namePerPassport";
		return "common.companyName";
	}

	getNCD() {
		if (!this.policyDetail) return 0;
		// -- Change to this if ncd using % -- //
		// if (this.requestType != 'NCD') {
		//   return (
		//     this.policyDetail?.basePremium - this.policyDetail?.totalBasePremium
		//   );
		// } else {
		//   return (this.requestAmount / 100) * this.policyDetail?.basePremium;
		// }
		return this.policyDetail?.basePremium - this.policyDetail?.totalBasePremium;
	}

	getUnpaidRemainingAmount() {
		if (!this.policyDetail) return 0;
		return this.getTotal() - this.policyDetail?.total;
	}

	getAddOnCode(code) {
		return `addOn.${code}`;
	}

	hasAddOn() {
		// return this.addOn.filter((x) => !x.hidePriceBreakdown).length != 0;
	}

	addonLabel(value: number) {
		return "RM " + value.toFixed(2).toLocaleString();
	}
}
