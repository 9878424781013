<button mat-icon-button class="dismiss-btn" (click)="dismissDialog()">
	<mat-icon>close</mat-icon>
</button>

<a (click)="faqDialog()" *ngIf="showFAQ">
	{{ "floatingMenu.faq" | translate }}
</a>

<a (click)="guideDialog()">
	{{ "floatingMenu.guide" | translate }}
</a>
