<div class="bg-light-grey">
	<div class="content-container small full-height text-center pv-lg">
		<img
			src="https://i.ibb.co/7th9wd0/tq-page-visual-1.png"
			alt=""
			class="mv-md"
		/>
		<p class="color-primary title-f">
			{{ "tq.title" | translate }}
		</p>
		<p>
			{{ "tq.operationHours" | translate }}
		</p>
		<p>{{ "common.thankyou" | translate }}!</p>
	</div>
</div>
