<router-outlet>
	<ngx-spinner
		bdColor="rgba(0, 0, 0, 0.8)"
		size="medium"
		color="#0c67ff"
		type="ball-triangle-path"
		[fullScreen]="true"
	>
	</ngx-spinner>
</router-outlet>
