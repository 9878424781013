import { Component } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { AlertService } from "./share/service/alert.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
})
export class AppComponent {
	constructor(
		swUpdate: SwUpdate,
		alertService: AlertService,
		translate: TranslateService,
	) {
		translate.addLangs(["en", "bm"]);
		translate.setDefaultLang("en");
		let browserLang = translate.getBrowserLang();
		translate.use(browserLang.match(/en|bm/) ? browserLang : "en");

		// Get the root element

		if (environment.production) {
			swUpdate.available.subscribe((event) => {
				alertService.openSnackBar(
					"A newer version is now available. Refresh the page now to update the cache",
				);
				location.reload();
			});
			swUpdate.checkForUpdate();
		}
	}
}
