<div>
    <div class="fail-graphic">
        <img src="assets/image/submit-dialog.svg" alt="" />
        <div>
            {{ "fail.requestFailed" | translate }}
        </div>
    </div>

    <div class="fail-content">
        <ng-container *ngIf="!data.isWithEHailing">
            <div class="fail-content__paragraph">
                {{ "fail.vehicleAgeLimit" | translate }} <span class="fail-content__paragraph--red">{{ "fail.unavailable" | translate }} </span> <span [innerHTML]="'fail.vehicleAgeLimit2' | translate : { numOfYears: 20 }"></span>
            </div>

            <div class="fail-content__paragraph" [innerHTML]="'fail.vehicleAgeLimit3' | translate"></div>

            <div class="fail-content__paragraph">
                {{ "common.thankyou" | translate }}.
            </div>
        </ng-container>

        <ng-container *ngIf="data.isWithEHailing">
            <div class="fail-content__paragraph">
                {{ "fail.vehicleAgeLimitWithEHailing" | translate }} <span class="fail-content__paragraph--red">{{ "fail.unavailable" | translate }} </span> <span [innerHTML]="'fail.vehicleAgeLimit2' | translate : { numOfYears: 15 }"></span>
            </div>
        </ng-container>

        <div class="fail-content__btn-container">
            <button *ngIf="!data.isWithEHailing" class="fail-content__btn fail-content__btn--fill" mat-flat-button (click)="onBackToHome()">
                {{ "fail.requestQuote" | translate }}
            </button>
            <button class="fail-content__btn" mat-stroked-button mat-dialog-close>
                {{ "success.close" | translate }}
            </button>
        </div>
    </div>
</div>  