import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ComparisonComponent } from "./comparison/comparison.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { StaticStylesComponent } from "./static-styles/static-styles.component";
import { ThankyouPageComponent } from "./thankyou-page/thankyou-page.component";
import { TopupRequestComponent } from "./topup-request/topup-request.component";
import { UploadDocumentsComponent } from "./upload-documents/upload-documents.component";

const routes: Routes = [
	{
		path: "",
		children: [
			{
				path: "",
				component: DashboardComponent,
			},
			{
				path: "comparison/:id",
				component: ComparisonComponent,
			},
			{
				path: "upload-documents/:id",
				component: UploadDocumentsComponent,
			},
			{
				path: "topup-request/:id",
				component: TopupRequestComponent,
			},
			{
				path: "checkout",
				component: ThankyouPageComponent,
			},
			{
				path: "styles",
				component: StaticStylesComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class DashboardRoutingModule {}
