import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BenefitComponent } from "./benefit.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "src/app/material-module";

@NgModule({
	exports: [BenefitComponent],
	declarations: [BenefitComponent],
	imports: [TranslateModule, CommonModule, MaterialModule],
})
export class BenefitModule {}
