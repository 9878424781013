import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-dealer-case-dialog",
	templateUrl: "./dealer-case-dialog.component.html",
	styleUrls: ["./dealer-case-dialog.component.scss"],
})
export class DealerCaseDialogComponent {
	constructor(public dialogRef: MatDialogRef<DealerCaseDialogComponent>) {}

	cancel() {
		this.dialogRef.close(true);
	}
}
