export class FileFunction {
	static getBase64(file: File): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as any);
			reader.onerror = (error) => reject(error);
		});
	}

	static is2MB(file: File): boolean {
		return file.size <= 1024 * 1024 * 2; //2mb
	}

	static isImage(file: File): boolean {
		if (file && file["type"] == "image/svg+xml") return false;
		return file && file["type"].split("/")[0] == "image";
	}

	static isImageOrPdf(file: File): boolean {
		return this.isImage(file) || file["type"] == "application/pdf";
	}

	static isPdf(file: File): boolean {
		return file["type"] == "application/pdf";
	}
}
