import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import * as $ from "jquery";
import * as color from "./../../../assets/styles/colors/partner-colors.json";

@Injectable({
	providedIn: "root",
})
export class PartnerService {
	colorObject = (color as any).default;

	psHostName: string[] = ["localhost"];
	partnerList = [
		{
			code: "carsomeCapital",
			title: "Carsome | Motor Insurance",
			hostName: [
				"carsomecapital.policystreet.app",
				"carsomecapital.policystreet.com",
				"uat-carsomecapital.policystreet.app"
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
		{
			code: "mytukar",
			title: "myTukar | Motor Insurance",
			hostName: [
				"mytukar.policystreet.app", 
				"mytukar.policystreet.com",
				"uat-mytukar.policystreet.app",
			],
			gtmID: "",
			uniquePageViews: false,
			takaful: false,
		},
	];
	constructor(
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
	) {}
	getPartnerCode() {
		let hostName = window.location.hostname?.toLowerCase();
		let urlParams = new URLSearchParams(window.location.search);
		let partnerCode = urlParams.get("partnerCode");

		// set PartnerCode on query params
		if (partnerCode) {
			let partner = this.partnerList.find((x) => x.code == partnerCode);
			if (!partner) {
				partnerCode = partner?.code;
			}
		}

		// set PartnerCode on custom subdomain
		if (hostName && !this.psHostName.some((x) => x.toLowerCase() == hostName)) {
			let selectedPartner = this.partnerList.find((x) =>
				x.hostName.includes(hostName),
			);
			partnerCode = selectedPartner?.code;
		}

		// Change favicon
		if (partnerCode) {
			this.titleService.setTitle(
				this.partnerList.find((x) => x.code == partnerCode)?.title,
			);
			$("link[rel*='icon']").attr(
				"href",
				`assets/icons/affiliate/${partnerCode}.ico`,
			);
		}
		// Switch the styles on partnerCode
		let colorList = this.colorObject[partnerCode ?? "default"];
		colorList.forEach((x: { root: string; value: string }) => {
			document.documentElement.style.setProperty(x.root, x.value);
		});

		return partnerCode;
	}

	getPartnerAttribute(partnerCode: string, type: string) {
		let partner = this.partnerList.find((x) => x[type] == true);
		if (partner && partner.code == partnerCode) return true;
		else return false;
	}

	appendGtmScript(gtmCode: string) {
		var headScript = `<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${gtmCode}');</script>`;
		var bodyScript = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmCode}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
		this.appendHeaderScript(headScript);
		this.appendBodyScript(bodyScript);
	}

	appendHeaderScript(script: string) {
		$("head").append(script);
	}

	appendBodyScript(script: string) {
		$("body").append(script);
	}
}
