import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslatePipe } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MasterDataDataService } from "src/app/share/data-service/master-data.data.service";
import { TransferOwnershipDataService } from "src/app/share/data-service/transfer-ownership.data.service";
import {
	MotorRequestInterface,
	ProductInterface,
	QuotationInterface,
} from "src/app/share/interface/motor.interface";
import { AlertService } from "src/app/share/service/alert.service";
import { PartnerService } from "src/app/share/service/partner.service";

@Component({
	selector: "app-edit-quotations",
	templateUrl: "./edit-quotations.component.html",
	styleUrls: ["./edit-quotations.component.scss"],
	providers: [TranslatePipe],
})
export class EditQuotationsComponent implements OnInit {
	partnerCode: string;
	formGroup: FormGroup;

	allFormGroup: FormGroup;
	specificFormGroup: FormGroup;

	motorProductList: ProductInterface[] = [];
	filterMotorProductList: ProductInterface[] = [];
	comMotorProductList: ProductInterface[];
	tpftMotorProductList: ProductInterface[];
	allMotorProductList: ProductInterface[];

	vehicleData: any;
	ncdList: number[];

	constructor(
		private translatePipe: TranslatePipe,
		private masterDataDataService: MasterDataDataService,
		private transferOwnershipDataService: TransferOwnershipDataService,
		private ngxSpinnerService: NgxSpinnerService,
		private alertService: AlertService,
		private router: Router,
		private partnerService: PartnerService,
		public dialogRef: MatDialogRef<EditQuotationsComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			id: string;
			request: MotorRequestInterface;
			quotationList: QuotationInterface[];
		},
	) {
		this.getPartnerCode();
		this.vehicleData = data.request;
		this.ncdList = this.masterDataDataService.getNcdList();
		this.getNonQuoteProduct();
	}

	private getPartnerCode() {
		this.partnerCode = this.partnerService.getPartnerCode();
		!this.partnerCode ? (this.partnerCode = "carsomeCapital") : null;
	}

	ngOnInit(): void {
		this.formGroup = new FormGroup({
			mobileNo: new FormControl(
				this.vehicleData.agentMobileNo,
				Validators.required,
			),
			email: new FormControl(this.vehicleData.agentEmail, [
				Validators.required,
				Validators.email,
			]),
			requestType: new FormControl("all", Validators.required),
		});
		this.allFormGroup = new FormGroup({
			sumInsured: new FormControl(null),
			previousVehicleNo: new FormControl(null),
			carModel: new FormControl(null),
			carMake: new FormControl(null),
			nvic: new FormControl(null),
			variant: new FormControl(null),
			typeOfVehicle: new FormControl(null),
			transmission: new FormControl(null),
			year: new FormControl(null),
			cc: new FormControl(null),
			seats: new FormControl(null),
			engineNo: new FormControl(null),
			chassisNo: new FormControl(null),
		});
		this.specificFormGroup = new FormGroup({
			selectedMotorProductList: new FormControl([]),
			sumInsured: new FormControl(null),
			engineNo: new FormControl(null),
			chassisNo: new FormControl(null),
		});
	}

	submit() {
		this.ngxSpinnerService.show();
		let values = {
			agentMobileNo: this.formGroup.value.mobileNo,
			agentEmail: this.formGroup.value.email,
			sumInsured: this.allFormGroup.value.sumInsured,
			previousVehicleNo: this.allFormGroup.value.previousVehicleNo,
			model: this.allFormGroup.value.carModel,
			make: this.allFormGroup.value.carMake,
			nvic: this.allFormGroup.value.nvic,
			variant: this.allFormGroup.value.variant,
			typeOfVehicle: this.allFormGroup.value.typeOfVehicle,
			transmission: this.allFormGroup.value.transmission,
			year: this.allFormGroup.value.year
				? parseInt(this.allFormGroup.value.year)
				: null,
			engineCC: this.allFormGroup.value.cc,
			seat: this.allFormGroup.value.seats,
			engineNo: this.allFormGroup.value.engineNo,
			chassisNo: this.allFormGroup.value.chassisNo,
		};

		let specificQuotesValues = {
			agentMobileNo: this.formGroup.value.mobileNo,
			agentEmail: this.formGroup.value.email,
			selectedMotorProductList:
				this.specificFormGroup.value.selectedMotorProductList,
			sumInsured: this.specificFormGroup.value.sumInsured,
			engineNo: this.specificFormGroup.value.engineNo
				? this.specificFormGroup.value.engineNo
				: this.vehicleData?.engineNo,
			chassisNo: this.specificFormGroup.value.chassisNo
				? this.specificFormGroup.value.chassisNo
				: this.vehicleData?.chassisNo,
		};

		if (this.formGroup.value.requestType == "all") {
			this.transferOwnershipDataService
				.editExistingQuotes(this.data.id, values)
				.subscribe({
					next: (x) => {
						this.ngxSpinnerService.hide();
						if (
							this.allFormGroup.value.sumInsured != null ||
							this.allFormGroup.value.previousVehicleNo != null ||
							this.allFormGroup.value.carModel != null ||
							this.allFormGroup.value.carMake != null ||
							this.allFormGroup.value.nvic != null ||
							this.allFormGroup.value.variant != null ||
							this.allFormGroup.value.typeOfVehicle != null ||
							this.allFormGroup.value.transmission != null ||
							this.allFormGroup.value.year != null ||
							this.allFormGroup.value.cc != null ||
							this.allFormGroup.value.variant != null
						) {
							this.dialogRef.close(true);
							this.router.navigate(["/"], {
								queryParams: {
									partnerCode: this.partnerCode,
								},
							});
						} else {
							this.dialogRef.close();
							window.location.reload();
						}
					},
					error: (err) => {
						this.ngxSpinnerService.hide();
						this.alertService.openSnackBar(
							"Failed sending edit quotation request",
						);
					},
				});
		} else {
			this.transferOwnershipDataService
				.editSpecificQuotes(this.data.id, specificQuotesValues)
				.subscribe({
					next: (x) => {
						this.ngxSpinnerService.hide();
						if (
							this.specificFormGroup.value.selectedMotorProductList.length !=
								0 ||
							this.specificFormGroup.value.sumInsured != null
						) {
							this.dialogRef.close(true);
							this.router.navigate(["/"], {
								queryParams: {
									partnerCode: this.partnerCode,
								},
							});
						} else {
							this.dialogRef.close();
							window.location.reload();
						}
					},
					error: (err) => {
						this.ngxSpinnerService.hide();
						this.alertService.openSnackBar(
							"Failed sending edit quotation request",
						);
					},
				});
		}
	}

	private getNonQuoteProduct() {
		this.transferOwnershipDataService
			.getAllProduct(this.data.id)
			.subscribe({
				next: (x) => {
					let list = x;
					this.allMotorProductList = list;
					this.comMotorProductList = list;
					this.tpftMotorProductList = list.filter(
						(x) => x.name.trim() != "Berjaya Sompo" && x.name.trim() != "Etiqa",
					);

					if (this.data.request.insuranceType) {
						this.getProduct(this.data.request.insuranceType);
					}
				},
			});
	}

	private getProduct(value) {
		if (value == "TP") Object.assign([], this.allMotorProductList);
		else {
			if (value == "COM") {
				this.motorProductList = Object.assign([], this.comMotorProductList);
			} else if (value == "TPFT") {
				this.motorProductList = Object.assign([], this.tpftMotorProductList);
			}
		}
		this.filterMotorProductSelection();
	}

	filterMotorProductSelection(searchValue: string = null) {
		if (searchValue) {
			let txt = searchValue.toLowerCase().trim();
			this.filterMotorProductList = this.motorProductList.filter((x) =>
				x.name.toLowerCase().includes(txt),
			);
		} else {
			this.filterMotorProductList = Object.assign([], this.motorProductList);
		}
	}

	getMotorProductDisplay() {
		if (this.motorProductList.length == 0) return null;
		if (this.getSelectedProductList().length == 1)
			return this.motorProductList.find(
				(x) => x.id == this.getSelectedProductList()[0],
			).name;
		else if (this.isSelectedAllMotorProduct())
			return this.translatePipe.transform("common.allInsurer");
		else
			return `${
				this.getSelectedProductList().length
			} ${this.translatePipe.transform("common.insurerSelected")}`;
	}

	getSelectedProductList() {
		return this.specificFormGroup.value.selectedMotorProductList;
	}

	isSelectedAllMotorProduct() {
		return this.getSelectedProductList().length == this.motorProductList.length;
	}

	isIndeterminateMotorProduct() {
		if (this.isSelectedAllMotorProduct()) return false;
		return this.getSelectedProductList().length > 0;
	}

	toggleSelectAllMotorProduct(val: boolean) {
		this.specificFormGroup.controls.selectedMotorProductList.setValue([]);
		if (val) {
			this.specificFormGroup.controls.selectedMotorProductList.setValue(
				this.motorProductList.map((y) => y.id),
			);
			this.specificFormGroup.controls.selectedMotorProductList.markAsDirty();
		}
	}
}
