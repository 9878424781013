import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: "app-thankyou-dialog",
	templateUrl: "./thankyou-dialog.component.html",
	styleUrls: ["./thankyou-dialog.component.scss"],
})
export class ThankyouDialogComponent {
	title: string;
	body: string;
	constructor(@Inject(MAT_DIALOG_DATA) data: any) {
		this.title = data.title;
		this.body = data.body;
	}
}
