<div class="main-container">
	<div class="loading-container" *ngIf="!finishedState">
		<div class="loading-container__title">
			{{ firstWording | translate }} <span>{{ keyword | translate }}</span>
			{{ secondWording | translate }}
			<span>{{ timeLeftState }} {{ "loadingPage.seconds" | translate }}</span
			>...
		</div>
		<div class="img-container">
			<img src="/assets/gif/Instant-Quotation-05-Looking.gif" alt="" />
		</div>
		<div class="loading-container__disclaimer">
			{{ "loadingPage.disclaimerText" | translate }}
		</div>
	</div>
	<ng-container *ngIf="finishedState">
		<div class="finished-state-container">
			<div class="finished-state-container__title">
				{{ "loadingPage.lapseTitle" | translate }}
			</div>
			<div class="img-container">
				<img src="/assets/gif/Instant-Quotation-05_Unsuccessful.gif" alt="" />
			</div>
			<div class="finished-state-container__desc">
				{{ "loadingPage.lapseDesc1" | translate }}
				<span>{{ this.formData?.get("email") || "-" }}</span>
				{{ "loadingPage.lapseDesc2" | translate }}
			</div>
			<button
				mat-flat-button
				class="finished-state-container__back-btn"
				(click)="backToHome()"
			>
				{{ "loadingPage.back" | translate }}
			</button>
		</div>
	</ng-container>
</div>
