import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import * as moment from "moment";

export class ValidatorFunction {
	static notPositive(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (
				Validators.required(control) !== undefined &&
				Validators.required(control) !== null
			)
				return null;
			if (Math.sign(Number(control.value)) != 1) {
				return { notPositive: true };
			} else {
				return null;
			}
		};
	}

	static notNegative(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (
				Validators.required(control) !== undefined &&
				Validators.required(control) !== null
			)
				return null;
			if (Number(control.value) < 0) {
				return { notNegative: true };
			} else {
				return null;
			}
		};
	}

	static notDecimal(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			if (
				Validators.required(control) !== undefined &&
				Validators.required(control) !== null
			)
				return null;
			if (!Number.isInteger(Number(control.value))) {
				return { notDecimal: true };
			} else {
				return null;
			}
		};
	}

	static nricValidator() {
		return (control: AbstractControl) => {
			if (
				Validators.required(control) !== undefined &&
				Validators.required(control) !== null
			)
				return null;

			let value: string = control.value;
			if (value.length == 14) {
				value = value.replace("-", "");
				value = value.replace("-", "");
				value = value.replace("-", "");
			}
			if (value.length == 12) {
				let year = value.slice(0, 2);
				let month = value.slice(2, 4);
				let day = value.slice(4, 6);
				let strDate = `${year}-${month}-${day}`;
				if (moment(strDate, "YY-MM-DD").isValid()) {
					return null;
				}
			}

			return { nric: true };
		};
	}
}
