<div class="thankyou-dialog">
	<div class="success-graphic" style="justify-content: center">
		<img src="assets/image/submit-success-dialog.png" alt="" />
		<div>{{ title }}</div>
	</div>
	<div class="success-content">
		<span>
			{{ body | translate }}
		</span>
		<button mat-flat-button class="secondary-btn" mat-dialog-close>
			{{ "success.close" | translate }}
		</button>
	</div>
</div>
