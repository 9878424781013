import {
	Component,
	HostListener,
	Input,
	OnInit,
	ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PartnerService } from "src/app/share/service/partner.service";
import { MenuMobileComponent } from "../menu/menu-mobile/menu-mobile.component";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: [],
})
export class AppHeaderComponent implements OnInit {
	partnerCode: string = "carsomeCapital";
	@Input() state = ""; // decorate the property with @Input()
	@ViewChild("menuTrigger") menuTrigger: MatMenuTrigger;

	@HostListener("window:scroll", []) onWindowScroll() {
		// do some stuff here when the window is scrolled
		const verticalOffset =
			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop ||
			0;
	}

	constructor(
		public translate: TranslateService,
		private dialog: MatDialog,
		private partnerService: PartnerService,
	) {}

	ngOnInit() {
		this.getPartnerCode();
	}

	private getPartnerCode() {
		this.partnerCode = this.partnerService.getPartnerCode();
		if (this.partnerCode == "ctos") {
			let partner = this.partnerService.partnerList.find(
				(x) => x.code == this.partnerCode,
			);
			this.partnerService.appendGtmScript(partner.gtmID);
			this.partnerService.appendHeaderScript(`<!-- BEGIN PLERDY CODE -->
      <script type="text/javascript" defer data-plerdy_code='1'>
      var _protocol="https:"==document.location.protocol?" https://":" http://";
      _site_hash_code = "93e87d8451fbf8a79fffee4e4da41f1d",_suid=25861, plerdyScript=document.createElement("script");
      plerdyScript.setAttribute("defer",""),plerdyScript.dataset.plerdymainscript="plerdymainscript",
      plerdyScript.src=https://a.plerdy.com/public/js/click/main.js?v=+Math.random();
      var plerdymainscript=document.querySelector("[data-plerdymainscript='plerdymainscript']");
      plerdymainscript&&plerdymainscript.parentNode.removeChild(plerdymainscript);
      try{document.head.appendChild(plerdyScript)}catch(t){console.log(t,"unable add script tag")}
      </script>
      <!-- END PLERDY CODE -->`);
		}
		!this.partnerCode ? (this.partnerCode = "carsomeCapital") : null;
	}

	openMenu() {
		this.dialog.open(MenuMobileComponent, {
			panelClass: "menu-dialog",
			backdropClass: "menu-blackdrop",
			disableClose: true,
		});
	}
	switch(lang: string) {
		this.translate.use(lang);
	}

	whatsappNumber = 60182822320;
	entityName = "Policystreet";
	whatsappContent = "";

	whatsppRedirect() {
		let lang = this.translate.currentLang == "en" ? "en" : "ms";
		this.whatsappContent =
			lang == "ms"
				? `Selamat datang ke ${this.entityName}! Bagaimana kami boleh bantu anda?
    1. Saya perlukan maklumat lanjut tentang polisi insurans tertentu.
    2. Insurans Motor - Tiada maklumat kereta ditemui.
    3. Insurans Motor - Jumlah Diinsuranskan.
    4. Insurans Motor – Pilihan syarikat insurans lain.
    5. Berbincang dengan ejen ${this.entityName}.
    Sila taip 1-5 berdasarkan permintaan di atas untuk mula.
    `
				: `Welcome to ${this.entityName}! How may we help you?
      1. I need more info on a particular insurance policy.
      2. Motor insurance - car info not found.
      3. Motor insurance - sum insured.
      4. Motor insurance - other insurer choices.
      5. Talk to a ${this.entityName} agent.
      Please type 1-5 based on the request above to start.`;

		let url = `https://api.whatsapp.com/send?phone=${
			this.whatsappNumber
		}&text=${encodeURIComponent(
			this.whatsappContent,
		)}&source=&data=&app_absent=&lang=${lang}`;

		window.open(url, "_blank");
	}
}
