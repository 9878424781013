import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";

@Component({
	selector: "app-before-expired-dialog",
	templateUrl: "./before-expired-dialog.component.html",
	styleUrls: ["./before-expired-dialog.component.css"],
})
export class BeforeExpiredDialogComponent {
	day: number;
	constructor(@Inject(MAT_DIALOG_DATA) data: string) {
		this.day = moment(data).diff(moment(), "day");
	}
}
