<div class="bg-light-grey">
	<div class="content-container pv-lg">
		<mat-card class="card-details">
			<mat-card-header>
				<mat-card-title class="bold">Fonts</mat-card-title>
			</mat-card-header>
			<mat-divider></mat-divider>
			<div class="content-wrapper p-sm">
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>
							.title-f
							<div class="">font: 20px 700</div>
						</div>
						<div class="title-f" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>
							.mdTitle-f
							<div class="">font: 18px 700</div>
						</div>
						<div class="mdTitle-f" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>
							.lgTitle-f
							<div class="">font: 36px 800</div>
						</div>
						<div class="lgTitle-f" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>
							.desc-f
							<div class="">font: 18px 500</div>
						</div>
						<div class="desc-f" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>
							.xsDesc-f
							<div class="">font: 11px 400</div>
						</div>
						<div class="xsDesc-f" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>
							.smDesc-f
							<div class="">font: 12px 400</div>
						</div>
						<div class="smDesc-f" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>
							.mdDesc-f
							<div class="">font: 12px 400</div>
						</div>
						<div class="mdDesc-f" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>
							.label-desc
							<div class="">font: 14px 500</div>
						</div>
						<div class="label-desc" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>
							.label-value
							<div class="">font: 14px 700</div>
						</div>
						<div class="label-value" fxFlex>Example</div>
					</div>
				</div>
			</div>
		</mat-card>
		<mat-card class="card-details mv-md">
			<mat-card-header>
				<mat-card-title class="bold">Colors</mat-card-title>
			</mat-card-header>
			<mat-divider></mat-divider>
			<div class="content-wrapper p-sm">
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>.color-primary</div>
						<div class="color-primary bold" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>.color-secondary</div>
						<div class="color-secondary bold" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>.color-green</div>
						<div class="color-green bold" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>.color-blue</div>
						<div class="color-blue bold" fxFlex>Example</div>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex>.color-red</div>
						<div class="color-red bold" fxFlex>Example</div>
					</div>
				</div>
			</div>
		</mat-card>
		<mat-card class="card-details mv-md">
			<mat-card-header>
				<mat-card-title class="bold">Buttons</mat-card-title>
			</mat-card-header>
			<mat-divider></mat-divider>
			<div class="content-wrapper p-sm">
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex style="flex: 1">.full-btn</div>
						<button mat-flat-button class="full-btn" style="flex: 1">
							Example
						</button>
					</div>
				</div>
				<div>
					<div fxLayout="row" fxLayoutAlign=" center">
						<div fxFlex style="flex: 1">.full-btn third</div>
						<button mat-flat-button class="full-btn third" style="flex: 1">
							Example
						</button>
					</div>
				</div>
			</div>
		</mat-card>
		<mat-card class="card-details mv-md">
			<mat-card-header>
				<mat-card-title class="bold">Radio Button</mat-card-title>
			</mat-card-header>
			<mat-divider></mat-divider>
			<div class="content-wrapper p-sm">
				<div>
					<pre>
            &lt;mat-radio-group class="b2c-radio"&gt;
              &lt;mat-radio-button value="example-1" selected&gt;
                &lt;Exmaple 1&gt;
              &lt;/mat-radio-button&gt;
              &lt;mat-radio-button value="example-2" selected&gt;
                &lt;Example 2&gt;
              &lt;/mat-radio-button&gt;
            &lt;/mat-radio-group&gt;
          </pre>
				</div>
			</div>
		</mat-card>
	</div>
</div>
