<div class="affiliate-footer" [ngClass]="partnerCode + '-affiliate-footer'">
	<div>
		<div class="copyright">
			<div class="logo-container">
				<img
					src="./assets/image/{{ partnerCode }}/{{ partnerCode }}-logo.png"
					alt="footer_logo"
					class="logo"
				/>
			</div>
			<p class="copyright-text"></p>
		</div>
		<div class="ps" *ngIf="partnerCode !== 'carsomeCapital'">
			<hr style="height: 100px" />
			<div class="powered-by">
				<p>Powered by</p>
				<img src="./assets/image/ps-logo-clear.svg" alt="" *ngIf="isPSFooter" />
			</div>
		</div>
		<div class="address">
			<p *ngIf="!isCustomFooter">
				D-3A-06 & D-3A-07, Menara Suezcap 1, KL Gateway, No. 2 Jalan Kerinchi,
				Gerbang Kerinchi Lestari, 59200 Kuala Lumpur
			</p>
			<p *ngIf="isCustomFooter" class="address-content"></p>
		</div>
		<div class="contact">
			<!-- <p class="phone">
        <span style="padding: 0px; vertical-align: middle"></span>
        <img
          src="./assets/icons/footer/phone-fill.png"
          alt=""
          width="28px"
          style="padding-right: 10px"
        />
        <span *ngIf="!isCustomFooter"> +603-3310 0063 </span>
        <span *ngIf="isCustomFooter" class="phone-content"> </span>
      </p> -->
			<p class="whatsapp">
				<span style="padding: 0px; vertical-align: middle">
					<img
						src="./assets/icons/footer/whatsapp-fill.png"
						alt=""
						width="28px"
						style="padding-right: 10px"
						*ngIf="!isCustomFooter"
					/>
				</span>
				<span *ngIf="!isCustomFooter"> +60111-684 2590 </span>
				<span *ngIf="isCustomFooter" class="whatsapp-content"> </span>
			</p>
			<p class="email">
				<span style="padding: 0px; vertical-align: middle">
					<img
						src="./assets/icons/footer/email-fill.png"
						alt=""
						width="28px"
						style="padding-right: 10px"
					/>
				</span>
				<span *ngIf="!isCustomFooter"> customerservice@policystreet.com </span>
				<span *ngIf="isCustomFooter" class="email-content"> </span>
			</p>
		</div>
	</div>
</div>
<div class="regNo" [ngClass]="{ regNoPaddingBtm: pathName !== '/' }">
	<p>
		Quotation and Policy issued by PolicyStreet (Polisea Sdn Bhd (Registration
		No:
		<span class="regNo-content"></span>)). Financial Adviser approved & licensed
		by Bank Negara Malaysia.
	</p>
</div>
