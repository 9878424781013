export class CommonFunction {
	static customSTMBRound(num: number): number {
		const decVal = parseFloat((num % 1).toFixed(2)); // Get 2 decimal point
		const decSubVal = parseFloat((num % 1).toFixed(2).substring(0, 3)); // Get 1 decimal point

		if (decVal >= parseFloat((decSubVal + 0.01).toFixed(2)) && decVal <= parseFloat((decSubVal + 0.02).toFixed(2))) {
			return Math.floor(num) + decSubVal;
		} else if (decVal >= parseFloat((decSubVal + 0.03).toFixed(2)) && decVal <= parseFloat((decSubVal + 0.05).toFixed(2))) {
			return Math.floor(num) + (decSubVal + 0.05);
		} else if (decVal >= parseFloat((decSubVal + 0.06).toFixed(2)) && decVal <= parseFloat((decSubVal + 0.07).toFixed(2))) {
			return Math.floor(num) + (decSubVal + 0.05);
		} else if (decVal >= parseFloat((decSubVal + 0.08).toFixed(2)) && decVal <= parseFloat((decSubVal + 0.09).toFixed(2))) {
			return Math.floor(num) + (decSubVal + 0.10);
		} else {
			return num;
		}
	}
}