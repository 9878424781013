<div>
	<div class="success-graphic">
		<img src="assets/image/submit-success-dialog.png" alt="" />
		<div>{{ "common.confirmationRequired" | translate }}</div>
	</div>
	<div class="success-content">
		<span> {{ "common.dealerCaseConfirm" | translate }} </span>
		<div fxLayout="row">
			<button
				mat-button
				(click)="cancel()"
				class="cancel-btn mr-xs"
				type="button"
			>
				{{ "common.cancel" | translate }}
			</button>
			<button mat-flat-button mat-dialog-close>
				{{ "common.confirm" | translate }}
			</button>
		</div>
	</div>
</div>
