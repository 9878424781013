import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppBlankComponent } from "./layouts/Blank/blank.component";
import { AppFullComponent } from "./layouts/full/full.component";

const routes: Routes = [
	{
		path: "",
		component: AppFullComponent,
		children: [
			{
				path: "",
				loadChildren: () =>
					import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
			},
		],
	},
	{
		path: "",
		component: AppBlankComponent,
		children: [
			{
				path: "payment",
				loadChildren: () =>
					import("./payment/payment.module").then((m) => m.PaymentModule),
			},
		],
	},
	{
		path: "**",
		redirectTo: "/",
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
