<div fxFlex="50" fxLayout="row">
	<img
		*ngIf="!first"
		src="assets/image/protection-drivers.svg"
		alt=""
		class="icon-filter"
	/>
	<img
		*ngIf="first"
		src="assets/image/protection-drivers.svg"
		alt=""
		class="icon-filter-cheapest"
	/>
	<div>
		<div class="label">
			{{ "comparison.protectionDriver" | translate }}
		</div>
		<div class="value" [ngSwitch]="code">
			<div *ngSwitchCase="'GrabAXA'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'Pacific'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'MSIG'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'Pando'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'AXA'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'Sompo'">
				{{ "addOn.UnlimitedDrivers" | translate }}
			</div>
			<div *ngSwitchCase="'Takaful_Malaysia'">
				{{ "addOn.UnlimitedDrivers" | translate }}
			</div>
			<div *ngSwitchCase="'Liberty'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'Ikhlas'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'Allianz'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'Etiqa'">
				{{ "addOn.UnlimitedDrivers" | translate }}
			</div>
			<div *ngSwitchCase="'Tune'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'Zurich'">
				<span *ngIf="insuranceType === 'COM'">
					<!-- {{ "insurerDetails.unlimited" | translate }} -->
					1 {{ "common.driver" | translate | lowercase }}
				</span>
				<span *ngIf="insuranceType === 'TPFT'">
					1 {{ "common.driver" | translate | lowercase }}
				</span>
			</div>
			<div *ngSwitchCase="'RHB'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'Kurnia'">
				1 {{ "common.driver" | translate | lowercase }}
			</div>
			<div *ngSwitchCase="'Chubb'">
				2 {{ "common.drivers" | translate | lowercase }}
			</div>
		</div>
	</div>
</div>
<div
	fxFlex="50"
	fxLayout="row"
	*ngIf="insuranceType && insuranceType !== 'TPFT'"
>
	<img
		*ngIf="!first"
		src="assets/image/towing-service.svg"
		alt=""
		class="icon-filter"
	/>
	<img
		*ngIf="first"
		src="assets/image/towing-service.svg"
		alt=""
		class="icon-filter-cheapest"
	/>
	<div>
		<div class="label">
			{{ "insurerDetails.towingService" | translate }}
		</div>
		<div class="value" [ngSwitch]="code">
			<div *ngSwitchCase="'GrabAXA'">
				{{ "insurerDetails.upTo" | translate }} 30km
			</div>
			<div *ngSwitchCase="'Pacific'">
				{{ "insurerDetails.upTo" | translate }} RM 200
			</div>
			<div *ngSwitchCase="'MSIG'">
				{{ "insurerDetails.upTo" | translate }} RM 300
			</div>
			<div *ngSwitchCase="'Pando'">
				{{ "insurerDetails.upTo" | translate }} RM 350
			</div>
			<div *ngSwitchCase="'AXA'">
				{{ "insurerDetails.upTo" | translate }} 30km
			</div>
			<div *ngSwitchCase="'Sompo'">
				{{ "insurerDetails.upTo" | translate }} RM 300
			</div>
			<div *ngSwitchCase="'Takaful_Malaysia'">
				{{ "insurerDetails.upTo" | translate }} 50km
			</div>
			<div *ngSwitchCase="'Liberty'">
				{{ "insurerDetails.upTo" | translate }} 150km
			</div>
			<div *ngSwitchCase="'Ikhlas'">
				{{ "insurerDetails.upTo" | translate }} 100km
			</div>
			<div *ngSwitchCase="'Allianz'">
				{{ "insurerDetails.upTo" | translate }} 150km
			</div>
			<div *ngSwitchCase="'Etiqa'">
				{{ "insurerDetails.upTo" | translate }} RM 200
			</div>
			<div *ngSwitchCase="'Tune'">
				{{ "insurerDetails.upTo" | translate }} RM 200
			</div>
			<div *ngSwitchCase="'Zurich'">
				{{ "insurerDetails.upTo" | translate }} RM 200
			</div>
			<div *ngSwitchCase="'RHB'">
				{{ "insurerDetails.upTo" | translate }} RM 300
			</div>
			<div *ngSwitchCase="'Kurnia'">
				{{ "insurerDetails.upTo" | translate }} 150km
			</div>
			<div *ngSwitchCase="'Chubb'">
				{{ "insurerDetails.unlimited24Hours" | translate }}
			</div>
		</div>
	</div>
</div>
